import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { generatePath, Link as RouterLink } from 'react-router-dom'
import { Form } from 'react-final-form'
import Link from '@mui/material/Link'
import InfiniteScroll from 'react-infinite-scroll-component'
import Box from '@mui/material/Box'
import styles from './BDOHomepageTable.module.scss'
import genericSs from '@styles/generic.module.scss'
import Grid from '@mui/material/Grid'
import { debounceEventHandler, formatAmount, formatDateCalendarNoTime } from '../../helpers/helpers'
import Card from '../Common/Card'
import TableContainer from '../Common/TableContainer'
import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableBody from '../Common/TableBody'
import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import TableFiltersRow from '../Common/TableFiltersRow'
import { IUser } from '@common/interfaces/user'
import { IProspectsAggregation } from '@common/interfaces/prospects'
import { ROUTES } from '../../constants/routes'
import FullscreenModal from '../Common/FullscreenModal'
import { SalesforceLink, BoxLink, RegenerateIcon, ExpandAndMinimize } from '../Common/Icons'
import { buildFiltersDefaults } from '../../helpers/filters'
import { PROSPECTS_LIST_FILTERS_CONFIG, PER_PAGE } from '@common/constants/filters'
import { OPSReportingStatus } from '@common/interfaces/prospects'
import cn from 'classnames'
import { dateToString } from '../../helpers/helpers'
import FilterContainer from '../Filters/FilterContainer'
import { ILoadingData } from '../../redux/types'
import TableLoader from '../Common/TableLoader'
import { SalesforceStage, ClientInfoStatus } from '@common/interfaces/client'
import DashboardHeader from '../DashboardHeader'
import { IHeaderItem } from '../DashboardHeader/DashboardHeader'
import useTable from '../../hooks/useTable'
import { usePermissions } from '../../helpers/permissionContext'

interface IProps {
  prospectsAggregationData: ILoadingData<IProspectsAggregation>
  listProspects: (params: object) => void
  user: IUser
}

const BDOHomepageTable = ({ prospectsAggregationData, listProspects, user }: IProps) => {
  const { isLoading, prospectsAggregation } = useMemo(
    () => ({
      isLoading: prospectsAggregationData.isLoading,
      prospectsAggregation: prospectsAggregationData.data,
    }),
    [prospectsAggregationData],
  )

  const { isUW, isAdmin, isBDO } = usePermissions()

  const filtersDefaults = useMemo(() => {
    if (isAdmin) {
      return buildFiltersDefaults(PROSPECTS_LIST_FILTERS_CONFIG, {
        salesforceStage: [
          null,
          SalesforceStage.TermSheetNegotiation,
          SalesforceStage.OpsCall,
          SalesforceStage.GatheringInfo,
          SalesforceStage.IntroOrMention,
          SalesforceStage.Submission,
          SalesforceStage.QualifyingCall,
        ],
      })
    } else if (isUW) {
      return buildFiltersDefaults(PROSPECTS_LIST_FILTERS_CONFIG, {
        salesforceStage: [null, SalesforceStage.TermSheetNegotiation],
      })
    } else {
      return buildFiltersDefaults(PROSPECTS_LIST_FILTERS_CONFIG, {
        salesforceOwner: [user?.id],
        salesforceStage: [
          null,
          SalesforceStage.TermSheetNegotiation,
          SalesforceStage.OpsCall,
          SalesforceStage.GatheringInfo,
          SalesforceStage.IntroOrMention,
          SalesforceStage.Submission,
          SalesforceStage.QualifyingCall,
        ],
      })
    }
  }, [user, isAdmin, isUW])

  const {
    filters,
    handleFiltersChange,
    handleOrderChange,
    orderBy,
    quickFilter,
    handleQuickFilterChange,
  } = useTable({
    tableId: 'prospects',
    filtersDefaults,
    sortDefault: {
      field: 'last_deal_activity_date',
      direction: 'DESC' as const,
    },
    quickFilterDefault: isUW ? 'Term Sheet Negotiation' : 'Active Deals',
  })

  const [isModalShown, setIsModalShown] = useState(false)

  const fetchListProspects = useCallback(
    (data: any) => {
      const params = {
        ...data,
        filters: {
          ...data.filters,
        },
        isRefresh: data.isRefresh ?? false,
      }
      if (!params.filters.salesforceStage?.length) {
        delete params.filters.salesforceStage
      }
      if (
        params?.filters.lastActivityDateFrom &&
        typeof params.filters.lastActivityDateFrom !== 'string'
      ) {
        params.filters.lastActivityDateFrom = dateToString(params.filters.lastActivityDateFrom)
      }
      if (
        params?.filters.lastActivityDateTo &&
        typeof params.filters.lastActivityDateTo !== 'string'
      ) {
        params.filters.lastActivityDateTo = dateToString(params.filters.lastActivityDateTo)
      }

      listProspects(params)
    },
    [listProspects],
  )

  const debounceListProspects = useMemo(
    () => debounceEventHandler(fetchListProspects, 500),
    [fetchListProspects],
  )

  const filtersConfig = useMemo(
    () =>
      PROSPECTS_LIST_FILTERS_CONFIG.map((item) => ({
        ...item,
        options:
          item.field === 'clients'
            ? prospectsAggregation?.prospects?.map(({ clientName }) => ({
                value: clientName,
                label: clientName,
              }))
            : item.field === 'salesforceStage'
            ? [
                { value: null, label: 'Empty' },
                ...(prospectsAggregation?.salesforceStages?.map((stage: string) => ({
                  value: stage,
                  label: stage,
                })) || []),
              ]
            : item.field === 'salesforceOwner'
            ? [{ value: '', label: 'All' }].concat(
                prospectsAggregation?.users?.map(({ firstName, id }) => ({
                  value: id,
                  label: firstName,
                })) ?? [],
              )
            : item?.options,
      }))
        .filter((item) => item.field !== 'riskRating' || isAdmin)
        .map((filter) =>
          isBDO && filter.type === 'quickFilter'
            ? {
                ...filter,
                quickFilters: [
                  ...filter.quickFilters,
                  {
                    title: 'Assigned to Me',
                    isHidden: true,
                    filters: {
                      salesforceOwner: [user?.id],
                    },
                  },
                ],
              }
            : isUW && filter.type === 'quickFilter'
            ? {
                ...filter,
                quickFilters: [
                  ...filter.quickFilters,
                  {
                    title: 'Term Sheet Negotiation',
                    filters: {
                      salesforceStage: [SalesforceStage.TermSheetNegotiation],
                    },
                  },
                ],
              }
            : filter,
        ),
    [prospectsAggregation, isUW, isBDO, isAdmin, user],
  )
  const lastUpdatedDate = useMemo(
    () =>
      prospectsAggregation?.updatedAt
        ? new Date(prospectsAggregation?.updatedAt).toLocaleString().split(',')[0]
        : '',
    [prospectsAggregation],
  )

  const lastUpdatedTime = useMemo(
    () =>
      prospectsAggregation?.updatedAt
        ? new Date(prospectsAggregation?.updatedAt).toLocaleString().split(',')[1]
        : '',
    [prospectsAggregation],
  )

  useEffect(() => {
    debounceListProspects({
      page: 0,
      perPage: PER_PAGE,
      filters,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
    })
  }, [listProspects, orderBy, filters, debounceListProspects])

  const pctOfGoal = useMemo(() => {
    if (prospectsAggregation?.dealsGoal === 0) {
      return 0
    }
    return Number(
      ((prospectsAggregation?.dealsClosed / prospectsAggregation?.dealsGoal) * 100).toFixed(0),
    )
  }, [prospectsAggregation])

  const pctDiffReferrals = useMemo(() => {
    if (prospectsAggregation?.referralsLastYtd === 0) {
      return 0
    }
    return Number(
      (
        ((prospectsAggregation?.referralsThisYear - prospectsAggregation?.referralsLastYtd) /
          prospectsAggregation?.referralsLastYtd) *
        100
      ).toFixed(0),
    )
  }, [prospectsAggregation])

  const handleRefreshProspects = useCallback(() => {
    listProspects({
      page: 0,
      perPage: PER_PAGE,
      filters,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      isRefresh: true,
    })
  }, [listProspects, orderBy, filters])

  const loadMore = useCallback(() => {
    fetchListProspects({
      loadMore: true,
      page: Math.ceil(prospectsAggregation?.data.length / PER_PAGE),
      perPage: PER_PAGE,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
    })
  }, [prospectsAggregation, orderBy, filters, fetchListProspects])

  const bdDashboardHeaderItems: IHeaderItem[] = useMemo(
    () => [
      {
        title: 'Opportunities',
        type: 'number',
        value: prospectsAggregation?.numAccounts,
      },
      {
        title: 'Term Sheets',
        type: 'number',
        value: prospectsAggregation?.numTermSheets,
      },
      {
        title: 'Referrals',
        type: 'number',
        value: prospectsAggregation?.referralsThisYear,
        percentage: pctDiffReferrals,
        goal: prospectsAggregation?.referralsLastYear,
      },
      {
        title: 'Deals Closed',
        type: 'amount',
        value: prospectsAggregation?.dealsClosed,
        percentage: pctOfGoal,
        goal: formatAmount(prospectsAggregation?.dealsGoal, '$'),
      },
    ],
    [prospectsAggregation, pctDiffReferrals, pctOfGoal],
  )

  return (
    <Box pr={2}>
      <DashboardHeader headerItems={bdDashboardHeaderItems} />

      <Box py={1} pr={2}>
        <FullscreenModal
          isOpen={isModalShown}
          setIsOpen={setIsModalShown}
          classes={{ body: styles.fullScreenModal }}
        >
          <Card noHeaderMargin noPadding={!isModalShown} withBorder={false}>
            <TableContainer className={styles.table}>
              <Form
                onSubmit={handleFiltersChange}
                initialValues={filters}
                mutators={{
                  setFieldData: ([field, value], state, { changeValue }) => {
                    changeValue(state, field, () => value)
                  },
                }}
                render={({ values, handleSubmit, form: { mutators } }) => (
                  <FilterContainer
                    filters={filtersConfig}
                    handleSubmit={handleSubmit}
                    mutators={mutators}
                    values={values}
                    appliedQuickFilter={quickFilter}
                    handleAppliedQuickFilterChange={handleQuickFilterChange}
                    actions={
                      <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Grid
                          container
                          columnSpacing={2}
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Grid item>
                            <RegenerateIcon
                              action={handleRefreshProspects}
                              title={`Last updated at ${lastUpdatedTime} on ${lastUpdatedDate}`}
                            />
                          </Grid>
                          <Grid item>
                            <ExpandAndMinimize
                              action={() => setIsModalShown((prev) => !prev)}
                              isExpanded={isModalShown}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    }
                    appliedFilters={filters}
                  />
                )}
              />
              <Table>
                <TableHead>
                  <TableFiltersRow
                    filters={filtersConfig}
                    orderBy={orderBy}
                    handleOrderChange={handleOrderChange}
                  />
                </TableHead>
                <TableBody id="scrollableTableReporting">
                  {isLoading ? (
                    <TableLoader columnsCount={filtersConfig.length} rowsCount={10} height={36} />
                  ) : (
                    prospectsAggregation?.data &&
                    prospectsAggregation.data.length > 0 && (
                      <InfiniteScroll
                        dataLength={prospectsAggregation?.data.length}
                        next={loadMore}
                        hasMore={
                          prospectsAggregation?.data.length < prospectsAggregation?.totalItems
                        }
                        loader={
                          <TableLoader
                            columnsCount={filtersConfig.length}
                            rowsCount={1}
                            height={36}
                          />
                        }
                        scrollableTarget="scrollableTableReporting"
                      >
                        {prospectsAggregation.data.map((item) => (
                          <TableRow key={item.id}>
                            <TableCell className={genericSs.tableTextLeft}>
                              <Link
                                component={RouterLink}
                                to={generatePath(ROUTES.PROSPECT_PAGE, {
                                  id: item.opsReporting?.id,
                                })}
                              >
                                {item.clientName}
                                {item.clientInfo.clientStatus === ClientInfoStatus.Archived && (
                                  <span className={genericSs.grayCard}>Archived</span>
                                )}
                              </Link>
                            </TableCell>
                            <TableCell className={genericSs.tableTextLeft}>
                              {item.salesforceStage}
                            </TableCell>
                            <TableCell className={genericSs.tableTextLeft}>
                              {item.user && item.user.firstName}
                            </TableCell>
                            <TableCell className={genericSs.tableTextLeft}>
                              <SalesforceLink link={item.salesforceLink} size="small" />
                            </TableCell>
                            <TableCell className={genericSs.tableTextLeft}>
                              <BoxLink link={item.opsBoxLink} size="small" />
                            </TableCell>
                            {isAdmin && (
                              <TableCell className={genericSs.tableTextRight}>
                                {item.riskRating?.toFixed(2)}
                              </TableCell>
                            )}

                            <TableCell className={genericSs.tableTextLeft}>
                              <span
                                className={cn(styles.status, {
                                  [styles.statusNotStarted]:
                                    item.opsReporting?.status === OPSReportingStatus.NotStarted,
                                  [styles.statusInReview]:
                                    item.opsReporting?.status === OPSReportingStatus.InProgress,
                                  [styles.statusCompleted]:
                                    item.opsReporting?.status === OPSReportingStatus.Complete ||
                                    item.opsReporting?.status === OPSReportingStatus.Archived,
                                })}
                              >
                                {item.opsReporting?.status === OPSReportingStatus.Archived
                                  ? OPSReportingStatus.Complete
                                  : item.opsReporting?.status}
                              </span>
                            </TableCell>
                            <TableCell className={genericSs.tableTextRight}>
                              {formatDateCalendarNoTime(item.lastDealActivityDate)}
                            </TableCell>
                          </TableRow>
                        ))}
                      </InfiniteScroll>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </FullscreenModal>
      </Box>
    </Box>
  )
}

export default BDOHomepageTable

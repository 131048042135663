import React, { useMemo } from 'react'
import { generatePath } from 'react-router'
import Avatar from '@mui/material/Avatar'
import Tooltip from '@mui/material/Tooltip'
import cn from 'classnames'

import styles from './NotificationItem.module.scss'

import AtlasLogo from '@assets/images/atlas-logo.png'
import { IUser } from '@common/interfaces/user'
import { formatDate, formatDateCalendar, formatDateTime, formatPrice } from '../../helpers/helpers'
import {
  INotification,
  NotificationType,
  NOTIFICATION_TYPE_ACTION_MAP,
  ACCOUNT_ACTIVITY_NOTIFICATION_TYPES_MAP,
} from '@common/interfaces/notification'
import { REPORTING_DOCUMENT_TYPES } from '@common/constants/client'
import { ROUTES } from '../../constants/routes'
import { ACTIVITY_TYPE_LABEL } from '@common/interfaces/activity'
import { DueDiligenceDocumentRequestStep } from '@common/interfaces/dueDiligence'
import { IClientInfo } from '@common/interfaces/client'
import { htmlToText } from '@common/helpers/helpers'
import { PROSPECT_REPORTING_MAP } from '@common/interfaces/prospects'

// Has copy in server/src/services/notificationSender.ts
// Should be updated in both places
export const generateNotificationLocation = (
  notification: INotification,
  isAdmin: boolean = false,
  isBDO: boolean = false,
  isUW: boolean = false,
  isClientUser: boolean = false,
): string | null => {
  if (
    [
      NotificationType.NoteTagging,
      NotificationType.NoteReply,
      // NotificationType.DefaultDiligenceNoteEdit, TODO: Revert default note edit notficiation
      NotificationType.DefaultDiligenceNoteReply,
    ].includes(notification.type)
  ) {
    if (!notification.note) {
      return null
    }
    if (notification.note.borrowingBase) {
      return `${generatePath(ROUTES.BBC_SUMMARY, {
        id: notification.note.borrowingBase.id,
      })}?noteId=${notification.note.id}`
    }
    if (notification.note.opsReporting) {
      return `${generatePath(ROUTES.PROSPECT_PAGE, {
        id: notification.note.opsReporting.id,
      })}?noteId=${notification.note.id}`
    }
    if (notification.note.ongoingReporting) {
      switch (notification.note.ongoingReporting.type) {
        case REPORTING_DOCUMENT_TYPES.financials:
          return `${generatePath(ROUTES.ONGOING_REPORTING_SUMMARY, {
            id: notification.note.ongoingReporting.id,
          })}?noteId=${notification.note.id}`
        case REPORTING_DOCUMENT_TYPES.bankTransactions:
          return `${generatePath(ROUTES.BANK_TRANSACTIONS_UPLOAD, {
            id: notification.note.ongoingReporting.id,
          })}?noteId=${notification.note.id}`
        case REPORTING_DOCUMENT_TYPES.salesBySKU:
          return `${generatePath(ROUTES.ONGOING_REPORTING_SALES_BY_SKU, {
            id: notification.note.ongoingReporting.id,
          })}?noteId=${notification.note.id}`
        case REPORTING_DOCUMENT_TYPES.arGeneralLedger:
          return `${generatePath(ROUTES.ONGOING_REPORTING_AR_GENERAL_LEDGER, {
            id: notification.note.ongoingReporting.id,
          })}?noteId=${notification.note.id}`
        case REPORTING_DOCUMENT_TYPES.capTable:
          return `${generatePath(ROUTES.CAP_TABLE_UPLOAD, {
            id: notification.note.ongoingReporting.id,
          })}?noteId=${notification.note.id}`
        default:
          return null
      }
    }
    if (notification.note.clientInfo) {
      return `${generatePath(ROUTES.CLIENT_PAGE, { id: notification.note.clientInfo.id })}?noteId=${
        notification.note.id
      }`
    }
    const entityNoteMentions = notification.note.noteMentions?.find(({ entity }) => !!entity)
    if (entityNoteMentions) {
      return `${generatePath(ROUTES.ENTITY_PAGE, { id: entityNoteMentions.entityId })}?noteId=${
        notification.note.id
      }`
    }
    return null
  }

  if (notification.type === NotificationType.RequestFunding && notification.borrowingBaseId) {
    return generatePath(ROUTES.BBC_SUMMARY, { id: notification.borrowingBaseId })
  }

  if (
    [NotificationType.SubmitReporting, NotificationType.CodatPortfolioSync].includes(
      notification.type,
    )
  ) {
    return `${ROUTES.ANALYSIS_QUEUE}`
  }

  if (notification.type === NotificationType.OPSSync && notification.data?.opsReportingId) {
    return generatePath(ROUTES.PROSPECT_ANALYSIS_QUEUE, { id: notification.data?.opsReportingId })
  }

  if (
    [
      NotificationType.ActivityAssign,
      NotificationType.ChecksPostingIssue,
      NotificationType.BBCProcessingSkipped,
    ].includes(notification.type)
  ) {
    return ROUTES.ACTIVITY_QUEUE
  }

  if (
    notification.type === NotificationType.ClientIntakeSubmit &&
    notification.data?.opsReportingId
  ) {
    return generatePath(ROUTES.PROSPECT_APPLICATION_PAGE, { id: notification.data.opsReportingId })
  }
  if (
    notification.type === NotificationType.ClientIntakeSubmit &&
    notification.clientInfo?.opsReportings?.length
  ) {
    return generatePath(ROUTES.PROSPECT_APPLICATION_PAGE, {
      id: notification.clientInfo.opsReportings[0].id,
    })
  }

  if (
    notification.type === NotificationType.ClientApplicationFileUpload &&
    notification.clientInfo
  ) {
    return `${generatePath(ROUTES.DUE_DILIGENCE_VALIDATE_DOCUMENTS_PAGE, {
      id: notification.clientInfo.id,
    })}?documentRequestId=${notification.ddDocumentRequestId}`
  }

  if (notification.type === NotificationType.CodatUWSync && notification.clientInfo) {
    return `${generatePath(ROUTES.DUE_DILIGENCE_ANALYSIS_QUEUE, {
      id: notification.clientInfo.id,
    })}`
  }

  if (
    notification.type === NotificationType.ClientApplicationStepFinished &&
    notification.clientInfo
  ) {
    if (notification.data.step === DueDiligenceDocumentRequestStep.Team) {
      return `${generatePath(ROUTES.DUE_DILIGENCE_TEAM_PAGE, {
        id: notification.clientInfo.id,
      })}`
    }
    if (notification.data.step === DueDiligenceDocumentRequestStep.Financials) {
      return `${generatePath(ROUTES.DUE_DILIGENCE_CLIENT_FINANCIALS_PAGE, {
        id: notification.clientInfo.id,
      })}`
    }
    return `${generatePath(ROUTES.DUE_DILIGENCE, {
      id: notification.clientInfo.id,
    })}`
  }

  if (notification.type === NotificationType.ClientApplicationDocumentComment) {
    if (isClientUser) {
      return `${generatePath(ROUTES.DOCUMENT_REQUESTS)}?documentRequestId=${
        notification.ddDocumentRequestId
      }`
    } else {
      return `${generatePath(ROUTES.DUE_DILIGENCE_VALIDATE_DOCUMENTS_PAGE, {
        id: notification.clientInfo?.id,
      })}?documentRequestId=${notification.ddDocumentRequestId}&isComment=true`
    }
  }

  if (
    [
      NotificationType.OveradvanceCreated,
      NotificationType.OveradvanceUpdated,
      NotificationType.OveradvanceUpdatedPriority,
    ].includes(notification.type) &&
    notification.clientInfo
  ) {
    return generatePath(ROUTES.CLIENT_SETUP_LOAN_STRUCTURE_OVERADVANCE, {
      id: notification.clientInfo.id,
    })
  }

  if (
    [
      NotificationType.AmendmentCreated,
      NotificationType.AmendmentUpdated,
      NotificationType.AmendmentDeleted,
    ].includes(notification.type) &&
    notification.clientInfo
  ) {
    return `${generatePath(ROUTES.CLIENT_PAGE, {
      id: notification.clientInfo.id,
    })}?tab=Overview&scrollTo=amendments`
  }

  if (
    [
      NotificationType.TermLoanCreated,
      NotificationType.TermLoanUpdated,
      NotificationType.TermLoanDeleted,
      NotificationType.TermLoanActivityCreated,
      NotificationType.TermLoanActivityUpdated,
      NotificationType.TermLoanActivityDeleted,
    ].includes(notification.type) &&
    notification.clientInfo
  ) {
    return `${generatePath(ROUTES.CLIENT_PAGE, {
      id: notification.clientInfo.id,
    })}?tab=Overview&scrollTo=term-loans`
  }

  if (notification.clientInfo && isUW) {
    return generatePath(ROUTES.DUE_DILIGENCE, { id: notification.clientInfo.id })
  }

  if (notification.clientInfo && isBDO && notification.clientInfo.opsReportings?.length) {
    return generatePath(ROUTES.PROSPECT_PAGE, { id: notification.clientInfo.opsReportings[0].id })
  }

  if (notification.clientInfo) {
    return generatePath(ROUTES.CLIENT_PAGE, { id: notification.clientInfo.id })
  }

  return null
}

export const NotificationItemAvatar = ({
  className,
  user,
  clientInfo,
}: {
  className?: string
  user: IUser
  clientInfo?: IClientInfo
}) => {
  return (
    <Avatar
      className={className}
      alt={
        clientInfo ? clientInfo.clientName : user ? `${user.firstName} ${user.lastName}` : 'Atlas'
      }
      src={clientInfo ? clientInfo.iconUrl : user ? user?.avatar : AtlasLogo}
    >
      {clientInfo ? clientInfo.clientName[0] : `${user?.firstName[0]} ${user?.lastName[0]}`}
    </Avatar>
  )
}

export const NotificationItemDate = ({ createdAt }: { createdAt: string }) => {
  return (
    <Tooltip title={formatDateTime(createdAt)}>
      <span>{formatDateCalendar(createdAt)}</span>
    </Tooltip>
  )
}
export const NotificationItemUnreadIcon = () => {
  return <div className={styles.notificationItemUnreadIcon} />
}

const NotificationItemContentRequestFunding = ({
  className,
  notification,
}: {
  className?: string
  notification: INotification
}) => {
  return (
    <div className={cn(styles.notificationItemContent, className)}>
      {notification.user?.firstName}&nbsp;
      <span className={styles.notificationItemContentAlt}>requested funding for</span>&nbsp;
      {notification.clientName}
    </div>
  )
}

const NotificationItemContentCancelFunding = ({
  className,
  notification,
}: {
  className?: string
  notification: INotification
}) => {
  return (
    <div className={cn(styles.notificationItemContent, className)}>
      {notification.user?.firstName} has cancelled their funding request for{' '}
      {notification.clientName} on {formatDate(notification.data.recordDate)}
      {notification.data.reason ? ` because ${notification.data.reason}` : ''}.
    </div>
  )
}

const NotificationItemContentDeleteFunding = ({
  className,
  notification,
}: {
  className?: string
  notification: INotification
}) => {
  return (
    <div className={cn(styles.notificationItemContent, className)}>
      {notification.user?.firstName}&nbsp;
      <span className={styles.notificationItemContentAlt}>
        has deleted {notification.clientName}'s Funding for
      </span>
      &nbsp;
      {formatDate(notification.data.recordDate)}.
    </div>
  )
}

const NotificationItemContentSubmitReporting = ({
  className,
  notification,
}: {
  className?: string
  notification: INotification
}) => {
  return (
    <div className={cn(styles.notificationItemContent, className)}>
      {notification.user?.firstName}{' '}
      <span className={styles.notificationItemContentAlt}>submitted</span>{' '}
      {notification.data.types?.join(', ')} reporting for {notification.clientName}
    </div>
  )
}

const NotificationItemContentFeeAutomatically = ({
  className,
  notification,
}: {
  className?: string
  notification: INotification
}) => {
  return (
    <div className={cn(styles.notificationItemContent, className)}>
      {notification.clientName}{' '}
      <span className={styles.notificationItemContentAlt}>
        {notification.data.tense || 'has been'} automatically
      </span>{' '}
      charged a ${formatPrice(notification.data.amount)} {notification.data.type} fee on{' '}
      {formatDate(notification.data.recordDate)}
    </div>
  )
}

const NotificationItemContentAccountActivity = ({
  className,
  notification,
}: {
  className?: string
  notification: INotification
}) => {
  return (
    <div className={cn(styles.notificationItemContent, className)}>
      {notification.user?.firstName}{' '}
      <span className={styles.notificationItemContentAlt}>
        {NOTIFICATION_TYPE_ACTION_MAP[notification.type]} a
      </span>{' '}
      ${formatPrice(notification.data.amount)} {notification.data.type || ''}{' '}
      {ACCOUNT_ACTIVITY_NOTIFICATION_TYPES_MAP[notification.type]}{' '}
      <span className={styles.notificationItemContentAlt}>for</span> {notification.clientName}{' '}
      {notification.data.from ? ` from ${notification.data.from} ` : ''}
      <span className={styles.notificationItemContentAlt}>on</span>{' '}
      {formatDate(notification.data.recordDate)}
    </div>
  )
}

const NotificationItemContentActivityAssign = ({
  className,
  notification,
}: {
  className?: string
  notification: INotification
}) => {
  return (
    <div className={cn(styles.notificationItemContent, className)}>
      {notification.user?.firstName} assigned you{' '}
      {notification.clientName ? `${notification.clientName}'s ` : ''}
      {ACTIVITY_TYPE_LABEL[notification.data.type]}
    </div>
  )
}

const NotificationItemContentActivityDelete = ({
  className,
  notification,
}: {
  className?: string
  notification: INotification
}) => {
  return (
    <div className={cn(styles.notificationItemContent, className)}>
      {notification.user?.firstName}&nbsp;
      <span className={styles.notificationItemContentAlt}>has deleted a</span>&nbsp;
      {ACTIVITY_TYPE_LABEL[notification.data.type]} activity for&nbsp;
      <span className={styles.notificationItemContentAlt}>
        {formatDate(notification.data.recordDate)}
      </span>
    </div>
  )
}

const NotificationItemContentChecksPostingIssue = ({ className }: { className?: string }) => {
  return (
    <div className={cn(styles.notificationItemContent, className)}>
      There was an issue posting checks.{' '}
      <span className={styles.notificationItemContentAlt}>
        Some checks may not have uploaded to Box
      </span>
    </div>
  )
}

const NotificationItemContentLoanBalanceDiscrepency = ({
  className,
  notification,
}: {
  className?: string
  notification: INotification
}) => {
  return (
    <div className={cn(styles.notificationItemContent, className)}>{notification.data.message}</div>
  )
}

const NotificationItemContentNoteTagging = ({
  className,
  notification,
}: {
  className?: string
  notification: INotification
}) => {
  const relatedTo = useMemo(
    () =>
      notification.note
        ? [
            !notification.note.opsReporting ? notification.note.clientName : null,
            notification.note.opsReporting ? 'OPS' : null,
            notification.note.borrowingBase
              ? `BBC ${formatDate(notification.note.borrowingBase.recordDate)}`
              : '',
            notification.note.ongoingReporting
              ? `${notification.note.ongoingReporting.type} ${formatDate(
                  notification.note.ongoingReporting.recordDate,
                )}`
              : '',
            ...notification.note.noteMentions.map(({ entity }) => entity?.name),
          ].filter(Boolean)
        : [],
    [notification],
  )

  if (!notification.note) {
    return null
  }
  return (
    <div className={cn(styles.notificationItemContent, className)}>
      <div>
        {notification.user?.firstName}{' '}
        <span className={styles.notificationItemContentAlt}>left</span> a note related to{' '}
        {relatedTo.join(' and ')}.
      </div>
      <div className={styles.notificationItemContentNote}>{notification.note.noteText}</div>
    </div>
  )
}

const NotificationItemContentClientIntakeSubmit = ({
  className,
  notification,
}: {
  className?: string
  notification: INotification
}) => {
  return (
    <div className={cn(styles.notificationItemContent, className)}>
      {notification.clientName}{' '}
      <span className={styles.notificationItemContentAlt}>has submitted the intake form</span>
    </div>
  )
}

const NotificationItemContentAutoReportingConnected = ({
  className,
  notification,
}: {
  className?: string
  notification: INotification
}) => {
  return (
    <div className={cn(styles.notificationItemContent, className)}>
      {notification.clientName}{' '}
      <span className={styles.notificationItemContentAlt}>has connected to Auto Reporting</span>
    </div>
  )
}

const NotificationItemContentAutoReportingDisconnected = ({
  className,
  notification,
}: {
  className?: string
  notification: INotification
}) => {
  return (
    <div className={cn(styles.notificationItemContent, className)}>
      {notification.clientName}{' '}
      <span className={styles.notificationItemContentAlt}>
        has disconnected from Auto Reporting
      </span>
    </div>
  )
}

const NotificationItemContentDueDiligenceFileUpload = ({
  className,
  notification,
}: {
  className?: string
  notification: INotification
}) => {
  return (
    <div className={cn(styles.notificationItemContent, className)}>
      {notification.user?.firstName}{' '}
      <span className={styles.notificationItemContentAlt}>has uploaded a new</span>{' '}
      {notification.ddDocumentRequest?.type?.name || ''}{' '}
      <span className={styles.notificationItemContentAlt}>priority document for</span>{' '}
      {notification.clientName}
    </div>
  )
}

const NotificationItemContentDueDiligenceStepFinished = ({
  className,
  notification,
}: {
  className?: string
  notification: INotification
}) => {
  return (
    <div className={cn(styles.notificationItemContent, className)}>
      {notification.user?.firstName}{' '}
      <span className={styles.notificationItemContentAlt}>has completed the</span>{' '}
      {notification.data.step}{' '}
      <span className={styles.notificationItemContentAlt}>of the UW Application</span> for{' '}
      {notification.clientName}
    </div>
  )
}

const NotificationItemContentDueDiligenceDocumentComment = ({
  className,
  notification,
}: {
  className?: string
  notification: INotification
}) => {
  return (
    <div className={cn(styles.notificationItemContent, className)}>
      <div>
        {notification.user?.firstName}{' '}
        <span className={styles.notificationItemContentAlt}>left</span> a comment on{' '}
        {notification.ddDocumentRequest?.type?.name || ''} upload
      </div>
      <div className={styles.notificationItemContentNote}>
        {htmlToText(
          notification.ddDocumentRequestComment?.comment || notification.data.comment || '',
        )}
      </div>
    </div>
  )
}

const NotificationItemContentMaturityDateRenewal = ({
  className,
  notification,
}: {
  className?: string
  notification: INotification
}) => {
  return (
    <div className={cn(styles.notificationItemContent, className)}>
      {notification.clientName}'s maturity date has been renewed from{' '}
      {formatDate(notification.data.originalMaturityDate)} to{' '}
      {formatDate(notification.data.newMaturityDate)}
    </div>
  )
}

const NotificationItemContentOveradvanceCreated = ({
  className,
  notification,
}: {
  className?: string
  notification: INotification
}) => {
  return (
    <div className={cn(styles.notificationItemContent, className)}>
      {notification.user?.firstName} <span className={styles.notificationItemContentAlt}>has </span>
      created <span className={styles.notificationItemContentAlt}>a new </span>$
      {formatPrice(notification.data.amount)} overadvance
      <span className={styles.notificationItemContentAlt}> for </span>
      {notification.clientName}
    </div>
  )
}

const NotificationItemContentOveradvanceUpdated = ({
  className,
  notification,
}: {
  className?: string
  notification: INotification
}) => {
  return (
    <div className={cn(styles.notificationItemContent, className)}>
      {notification.user?.firstName} <span className={styles.notificationItemContentAlt}>has </span>
      edited <span className={styles.notificationItemContentAlt}>the settings of a </span>$
      {formatPrice(notification.data.amount)} overadvance
      <span className={styles.notificationItemContentAlt}> for </span>
      {notification.clientName}
    </div>
  )
}

const NotificationItemContentOveradvanceUpdatedPriority = ({
  className,
  notification,
}: {
  className?: string
  notification: INotification
}) => {
  return (
    <div className={cn(styles.notificationItemContent, className)}>
      {notification.user?.firstName} <span className={styles.notificationItemContentAlt}>has </span>
      edited<span className={styles.notificationItemContentAlt}> the </span>priority
      <span className={styles.notificationItemContentAlt}> of an </span>overadvance
      <span className={styles.notificationItemContentAlt}> for </span>
      {notification.clientName}
    </div>
  )
}

const NotificationItemContentOveradvanceDeleted = ({
  className,
  notification,
}: {
  className?: string
  notification: INotification
}) => {
  return (
    <div className={cn(styles.notificationItemContent, className)}>
      {notification.user?.firstName} <span className={styles.notificationItemContentAlt}>has </span>
      deleted<span className={styles.notificationItemContentAlt}> a </span>$
      {formatPrice(notification.data.amount)} overadvance
      <span className={styles.notificationItemContentAlt}> for </span>
      {notification.clientName}
    </div>
  )
}

const NotificationItemContentAmendmentCreated = ({
  className,
  notification,
}: {
  className?: string
  notification: INotification
}) => {
  return (
    <div className={cn(styles.notificationItemContent, className)}>
      {notification.user?.firstName} <span className={styles.notificationItemContentAlt}>has </span>
      added<span className={styles.notificationItemContentAlt}> a new </span>amendment{' '}
      <span className={styles.notificationItemContentAlt}>
        ({notification.data.summaryOfChanges || notification.data.termNames}) for{' '}
      </span>
      {notification.clientName}
      <span className={styles.notificationItemContentAlt}> effective </span>
      {formatDate(notification.data.effectiveDate)}
    </div>
  )
}

const NotificationItemContentAmendmentUpdated = ({
  className,
  notification,
}: {
  className?: string
  notification: INotification
}) => {
  return (
    <div className={cn(styles.notificationItemContent, className)}>
      {notification.user?.firstName} <span className={styles.notificationItemContentAlt}>has </span>
      edited <span className={styles.notificationItemContentAlt}>
        the settings of a{' '}
      </span>amendment{' '}
      <span className={styles.notificationItemContentAlt}>
        ({notification.data.summaryOfChanges || notification.data.termNames}) for{' '}
      </span>
      {notification.clientName}
      <span className={styles.notificationItemContentAlt}> effective </span>
      {formatDate(notification.data.effectiveDate)}
    </div>
  )
}

const NotificationItemContentAmendmentDeleted = ({
  className,
  notification,
}: {
  className?: string
  notification: INotification
}) => {
  return (
    <div className={cn(styles.notificationItemContent, className)}>
      {notification.user?.firstName} <span className={styles.notificationItemContentAlt}>has </span>
      deleted<span className={styles.notificationItemContentAlt}> an </span>amendment{' '}
      <span className={styles.notificationItemContentAlt}>
        ({notification.data.summaryOfChanges || notification.data.termNames}) for{' '}
      </span>
      {notification.clientName}
      <span className={styles.notificationItemContentAlt}> effective </span>
      {formatDate(notification.data.effectiveDate)}
    </div>
  )
}

const NotificationItemContentOPSSync = ({
  className,
  notification,
}: {
  className?: string
  notification: INotification
}) => {
  return (
    <div className={cn(styles.notificationItemContent, className)}>
      {notification.clientName}
      &nbsp;
      <span className={styles.notificationItemContentAlt}>
        {notification.data.types
          ?.map((type: string) => PROSPECT_REPORTING_MAP[type] || type)
          ?.join(', ')}
        &nbsp; OPS reporting&nbsp;
        {notification.data.status
          ? 'has been successfully updated.'
          : 'failed to update. Please try again.'}
      </span>
    </div>
  )
}

const NotificationItemContentCodatPortfolioSync = ({
  className,
  notification,
  isFull = true,
}: {
  className?: string
  notification: INotification
  isFull?: boolean
}) => {
  return (
    <div className={cn(styles.notificationItemContent, className)}>
      Codat reporting&nbsp;
      {notification.data.success.length > 0 && (
        <>
          has successfully refreshed for {notification.data.success.length} client
          {notification.data.success.length > 1 ? 's' : ''} on&nbsp;
          {formatDate(notification.data.recordDate)}.&nbsp;
        </>
      )}
      {notification.data.error.length > 0 && (
        <>
          Refresh failed for {notification.data.error.length} client
          {notification.data.error.length > 1 ? 's' : ''}.
          {isFull && (
            <ul>
              {notification.data.error.map(
                ({ clientName, types }: { clientName: string; types: string[] }) => (
                  <li key={clientName}>
                    {clientName}
                    {types?.length > 0 &&
                      `: ${types.map((type) => PROSPECT_REPORTING_MAP[type] || type).join(', ')}`}
                  </li>
                ),
              )}
            </ul>
          )}
        </>
      )}
    </div>
  )
}

const NotificationItemContentCodatUWSync = ({
  className,
  notification,
}: {
  className?: string
  notification: INotification
}) => {
  return (
    <div className={cn(styles.notificationItemContent, className)}>
      {notification.clientName}&nbsp;
      <span className={styles.notificationItemContentAlt}>
        {PROSPECT_REPORTING_MAP[notification.data.type] || notification.data.type}&nbsp; Codat
        reporting&nbsp;
        {notification.data.status
          ? 'has been successfully updated.'
          : 'failed to update. Please try again.'}
      </span>
    </div>
  )
}

const NotificationItemContentBBCProcessingSkipped = ({
  className,
  notification,
}: {
  className?: string
  notification: INotification
}) => {
  return (
    <div className={cn(styles.notificationItemContent, className)}>
      {notification.user?.firstName}&nbsp;
      <span className={styles.notificationItemContentAlt}>requested funding for</span>&nbsp;
      {notification.clientName}
      <span className={styles.notificationItemContentAlt}>
        , however the BBC was not pre-processed. Please complete the existing test BBC first.
      </span>
    </div>
  )
}

const NotificationItemTermLoanContent = ({
  className,
  notification,
}: {
  className?: string
  notification: INotification
}) => {
  return (
    <div className={cn(styles.notificationItemContent, className)}>
      {notification.user?.firstName}{' '}
      <span className={styles.notificationItemContentAlt}>
        {NOTIFICATION_TYPE_ACTION_MAP[notification.type]}{' '}
      </span>{' '}
      <span className={styles.notificationItemContentAlt}>
        {' '}
        a ${formatPrice(notification.data.loanAmount, 0)}{' '}
      </span>
      Term Loan ({notification.data.collateralName}) for {notification.clientName}{' '}
      {notification.type === NotificationType.TermLoanCreated ? (
        <>
          <span className={styles.notificationItemContentAlt}> effective </span>
          {formatDate(notification.data.closingDate)}
        </>
      ) : null}
    </div>
  )
}

const NotificationItemNoteReplyContent = ({
  className,
  notification,
}: {
  className?: string
  notification: INotification
}) => {
  return (
    <div className={cn(styles.notificationItemContent, className)}>
      {notification.user?.firstName}&nbsp;
      <span className={styles.notificationItemContentAlt}>replied to your note</span>
      <div className={styles.notificationItemContentNote}>{notification.data.replyNoteText}</div>
    </div>
  )
}
// TODO: Revert default note edit notficiation
// const NotificationItemSystemNoteEditContent = ({
//   className,
//   notification,
// }: {
//   className?: string
//   notification: INotification
// }) => {
//   return (
//     <div className={cn(styles.notificationItemContent, className)}>
//       {notification.user?.firstName}&nbsp;
//       <span className={styles.notificationItemContentAlt}>edited a note</span>
//       <div className={styles.notificationItemContentNote}>{notification.data.replyNoteText}</div>
//     </div>
//   )
// }

const COMPONENT_MAP = {
  [NotificationType.RequestFunding]: NotificationItemContentRequestFunding,
  [NotificationType.CancelFunding]: NotificationItemContentCancelFunding,
  [NotificationType.DeleteFunding]: NotificationItemContentDeleteFunding,
  [NotificationType.SubmitReporting]: NotificationItemContentSubmitReporting,
  [NotificationType.FeeCreated]: NotificationItemContentAccountActivity,
  [NotificationType.FeeUpdated]: NotificationItemContentAccountActivity,
  [NotificationType.FeeDeleted]: NotificationItemContentAccountActivity,
  [NotificationType.FeeCharged]: NotificationItemContentFeeAutomatically,
  [NotificationType.AdjustmentCreated]: NotificationItemContentAccountActivity,
  [NotificationType.AdjustmentUpdated]: NotificationItemContentAccountActivity,
  [NotificationType.AdjustmentDeleted]: NotificationItemContentAccountActivity,
  [NotificationType.PassThroughUpdated]: NotificationItemContentAccountActivity,
  [NotificationType.PassThroughDeleted]: NotificationItemContentAccountActivity,
  [NotificationType.WiresDataCreated]: NotificationItemContentAccountActivity,
  [NotificationType.WiresDataUpdated]: NotificationItemContentAccountActivity,
  [NotificationType.WiresDataDeleted]: NotificationItemContentAccountActivity,
  [NotificationType.CheckDataCreated]: NotificationItemContentAccountActivity,
  [NotificationType.CheckDataUpdated]: NotificationItemContentAccountActivity,
  [NotificationType.CheckDataDeleted]: NotificationItemContentAccountActivity,
  [NotificationType.TermLoanActivityCreated]: NotificationItemContentAccountActivity,
  [NotificationType.TermLoanActivityUpdated]: NotificationItemContentAccountActivity,
  [NotificationType.TermLoanActivityDeleted]: NotificationItemContentAccountActivity,
  [NotificationType.ActivityAssign]: NotificationItemContentActivityAssign,
  [NotificationType.ActivityDelete]: NotificationItemContentActivityDelete,
  [NotificationType.ChecksPostingIssue]: NotificationItemContentChecksPostingIssue,
  [NotificationType.LoanBalanceDiscrepancy]: NotificationItemContentLoanBalanceDiscrepency,
  [NotificationType.LoanBalanceDiscrepancyResolved]: NotificationItemContentLoanBalanceDiscrepency,
  [NotificationType.NoteTagging]: NotificationItemContentNoteTagging,
  [NotificationType.ClientIntakeSubmit]: NotificationItemContentClientIntakeSubmit,
  [NotificationType.AutoReportingConnected]: NotificationItemContentAutoReportingConnected,
  [NotificationType.AutoReportingDisconnected]: NotificationItemContentAutoReportingDisconnected,
  [NotificationType.ClientApplicationFileUpload]: NotificationItemContentDueDiligenceFileUpload,
  [NotificationType.ClientApplicationStepFinished]: NotificationItemContentDueDiligenceStepFinished,
  [NotificationType.ClientApplicationDocumentComment]:
    NotificationItemContentDueDiligenceDocumentComment,
  [NotificationType.MaturityDateRenewal]: NotificationItemContentMaturityDateRenewal,
  [NotificationType.OveradvanceCreated]: NotificationItemContentOveradvanceCreated,
  [NotificationType.OveradvanceUpdated]: NotificationItemContentOveradvanceUpdated,
  [NotificationType.OveradvanceUpdatedPriority]: NotificationItemContentOveradvanceUpdatedPriority,
  [NotificationType.OveradvanceDeleted]: NotificationItemContentOveradvanceDeleted,
  [NotificationType.AmendmentCreated]: NotificationItemContentAmendmentCreated,
  [NotificationType.AmendmentUpdated]: NotificationItemContentAmendmentUpdated,
  [NotificationType.AmendmentDeleted]: NotificationItemContentAmendmentDeleted,
  [NotificationType.OPSSync]: NotificationItemContentOPSSync,
  [NotificationType.CodatPortfolioSync]: NotificationItemContentCodatPortfolioSync,
  [NotificationType.CodatUWSync]: NotificationItemContentCodatUWSync,
  [NotificationType.BBCProcessingSkipped]: NotificationItemContentBBCProcessingSkipped,
  [NotificationType.TermLoanCreated]: NotificationItemTermLoanContent,
  [NotificationType.TermLoanUpdated]: NotificationItemTermLoanContent,
  [NotificationType.TermLoanDeleted]: NotificationItemTermLoanContent,
  [NotificationType.NoteReply]: NotificationItemNoteReplyContent,
  // [NotificationType.DefaultDiligenceNoteEdit]: NotificationItemSystemNoteEditContent,  TODO: Revert default note edit notficiation
  [NotificationType.DefaultDiligenceNoteReply]: NotificationItemNoteReplyContent,
}

// Has copy in server/src/services/notificationSender.ts
// Should be updated in both places
export const NotificationItemContent = ({
  className,
  notification,
  isFull = true,
}: {
  className?: string
  notification: INotification
  isFull?: boolean
}): any => {
  if (!COMPONENT_MAP[notification.type]) {
    return null
  }
  const Component = COMPONENT_MAP[notification.type]

  return <Component className={className} notification={notification} isFull={isFull} />
}

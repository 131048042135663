import React, { useEffect, useCallback, useState, useMemo } from 'react'
import { useParams } from 'react-router'
import { useHistory } from 'react-router'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { ROUTES } from '../../constants/routes'
import Breadcrumbs from '../../components/Common/Breadcrumbs'
import { ReactComponent as HomeIcon } from '@assets/images/home-icon.svg'
import { generatePath } from 'react-router-dom'
import { IOngoingReporting, OngoingReportingStatus } from '@common/interfaces/bbc'
import { ILoadingData } from '../../redux/types'
import { ICapTableMappingData } from '@common/interfaces/capTable'
import OngoingReportingFiles from '../../components/OngoingReportingFiles'
import CapTableSummaryTable from '../../components/CapTableSummaryTable'
import { REPORTING_DOCUMENT_TYPES } from '@common/constants/client'
import FullscreenModal from '../../components/Common/FullscreenModal'
import CapTableMappingTable from '../../components/CapTableMappingTable'
import genericSs from '@styles/generic.module.scss'
import styles from './CapTablePage.module.scss'
import Button from '../../components/Common/Button'

interface IProps {
  ongoingReporting: IOngoingReporting
  showOngoingReporting: (id: string) => Promise<void>
  capTableMapping: ILoadingData<ICapTableMappingData>
  listMapping: (id: string, params: object) => void
  listCapTable: (params: object) => void
  updateOngoingReportingStatus: (data: object) => Promise<void>
  processCapTable: (id: string, data: object) => Promise<any>
  showClient: (id: string) => void
  isLoading: boolean
}

const CapTablePage = ({
  listMapping,
  showOngoingReporting,
  updateOngoingReportingStatus,
  ongoingReporting,
  listCapTable,
  processCapTable,
  showClient,
  isLoading,
  capTableMapping,
}: IProps) => {
  const { id } = useParams<{ id?: string }>()

  const [isMappingModalOpen, setIsMappingModalOpen] = useState(false)
  const [mappingComplete, setMappingComplete] = useState(null)
  const [capTableFilters, setCapTableFilters] = useState(null)
  const [mappingDate, setMappingDate] = useState(null)
  const history = useHistory()

  const { data: mappingData } = useMemo(() => {
    return {
      data: capTableMapping?.data?.data,
    }
  }, [capTableMapping])

  const handleListMapping = useCallback(async () => {
    if (
      ongoingReporting?.clientName &&
      ongoingReporting?.filesMapping?.[REPORTING_DOCUMENT_TYPES.capTable] &&
      mappingDate
    ) {
      await listMapping(id, {
        recordDate: mappingDate,
        clientName: ongoingReporting?.clientName,
        orderBy:
          'CAST(SUM(capTableDetails.dilutedShares) AS DECIMAL) / CAST(:allShares AS DECIMAL)',
        orderDirection: 'DESC',
      })
    }
  }, [id, listMapping, ongoingReporting, mappingDate])

  const handleListCapTable = useCallback(async () => {
    if (ongoingReporting?.clientName || ongoingReporting?.recordDate) {
      if (capTableFilters) {
        await listCapTable(capTableFilters)
      } else {
        await listCapTable({
          clientName: ongoingReporting?.clientName,
          recordDate: ongoingReporting?.recordDate,
          orderBy: 'SUM("capTableDetails"."diluted_shares")',
          orderDirection: 'DESC',
        })
      }
    }
  }, [listCapTable, capTableFilters, ongoingReporting])

  useEffect(() => {
    handleListMapping()
  }, [handleListMapping])

  useEffect(() => {
    handleListCapTable()
  }, [handleListCapTable])

  useEffect(() => {
    if (ongoingReporting?.clientInfo?.id) {
      showClient(ongoingReporting?.clientInfo?.id)
    }
  }, [ongoingReporting?.clientInfo?.id, showClient])

  useEffect(() => {
    const isMappingComplete = mappingData?.every(
      (item) =>
        item.totalDilutedShares <= 0.05 ||
        (item.totalDilutedShares > 0.05 && item.investor !== null),
    )

    setMappingComplete(isMappingComplete)
  }, [mappingData, setMappingComplete])

  const handleOpenMappingModal = useCallback(() => {
    setIsMappingModalOpen(true)
  }, [])

  const handleClick = useCallback(() => {
    if (ongoingReporting?.status === OngoingReportingStatus.Process) {
      updateOngoingReportingStatus({ id, status: OngoingReportingStatus.InReview })
    } else if (ongoingReporting?.status === OngoingReportingStatus.InReview) {
      updateOngoingReportingStatus({ id, status: OngoingReportingStatus.Verified })
    }
    history.push(ROUTES.ANALYSIS_QUEUE)
  }, [id, updateOngoingReportingStatus, history, ongoingReporting?.status])

  const handleCloseMappingModal = useCallback(async () => {
    await handleListCapTable()
    setIsMappingModalOpen(false)
  }, [handleListCapTable])

  const breadcrumbs = useMemo(() => {
    return [
      {
        link: ROUTES.HOMEPAGE,
        Icon: HomeIcon,
      },
      {
        link: ROUTES.ANALYSIS_QUEUE,
        title: 'Analysis queue',
      },
      {
        title: ongoingReporting?.clientName,
        link: ongoingReporting?.clientInfo?.id
          ? generatePath(ROUTES.CLIENT_PAGE, { id: ongoingReporting?.clientInfo?.id })
          : '',
      },
      {
        link: generatePath(ROUTES.CAP_TABLE_UPLOAD, { id }),
        title: `Cap Table reporting`,
      },
    ]
  }, [id, ongoingReporting])

  const isDisabled = useMemo(
    () =>
      [OngoingReportingStatus.Verified, OngoingReportingStatus.Archived].includes(
        ongoingReporting?.status,
      ),
    [ongoingReporting],
  )

  const handleSelectFile = useCallback(
    async (fileId: string, sheetName: string) => {
      await processCapTable(id, {
        filesMapping: {
          [REPORTING_DOCUMENT_TYPES.capTable]: {
            fileId,
            sheetName,
          },
        },
      })
      await showOngoingReporting(id)
      await handleListCapTable()
      await handleListMapping()
    },
    [id, processCapTable, showOngoingReporting, handleListCapTable, handleListMapping],
  )

  useEffect(() => {
    showOngoingReporting(id)
  }, [showOngoingReporting, id])

  const [isFileSelectShown, setIsFileSelectShown] = useState(false)
  const handleOpenSelectFile = useCallback(() => {
    setIsFileSelectShown(true)
  }, [setIsFileSelectShown])

  const handleCloseSelectFile = useCallback(async () => {
    setIsFileSelectShown(false)
  }, [setIsFileSelectShown])

  useEffect(() => {
    if (ongoingReporting && mappingComplete === false) {
      handleOpenMappingModal()
      handleCloseSelectFile()
    }
  }, [ongoingReporting, mappingComplete, handleCloseSelectFile, handleOpenMappingModal])

  const selectedFile = ongoingReporting?.files?.find(
    ({ id }) => id === ongoingReporting?.filesMapping?.[REPORTING_DOCUMENT_TYPES.capTable]?.fileId,
  )

  return (
    <Box py={1} pr={2}>
      <div className={styles.topContainer}>
        <Breadcrumbs breadcrumbs={breadcrumbs} isLoading={isLoading} />
        {isDisabled ? (
          <Button
            type="submit"
            color="primary"
            variant="contained"
            size="small"
            href={ROUTES.ANALYSIS_QUEUE}
          >
            Return to Analysis queue
          </Button>
        ) : (
          <Button
            type="submit"
            color="primary"
            variant="contained"
            size="small"
            onClick={handleClick}
          >
            Submit
          </Button>
        )}
      </div>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <OngoingReportingFiles
            handleSelectFile={handleSelectFile}
            selectedFile={ongoingReporting?.filesMapping?.[REPORTING_DOCUMENT_TYPES.capTable]}
            files={ongoingReporting?.files}
            isDisabled={isDisabled}
            handleCloseModal={handleCloseSelectFile}
            handleOpenModal={handleOpenSelectFile}
            isOpen={isFileSelectShown}
          />
        </Grid>
      </Grid>

      <CapTableSummaryTable
        handleOpenMappingModal={handleOpenMappingModal}
        mappingComplete={mappingComplete}
        boxLink={selectedFile?.link}
        handleOpenSourceData={handleOpenSelectFile}
        isFileSelectShown={isFileSelectShown}
        selectedFile={selectedFile}
        setCapTableFilters={setCapTableFilters}
        isDisabled={isDisabled}
        setMappingDate={setMappingDate}
      />
      {isMappingModalOpen && (
        <FullscreenModal
          isOpen={isMappingModalOpen}
          setIsOpen={handleCloseMappingModal}
          showCloseIcon
          classes={{ body: styles.fullScreenModal }}
        >
          <div className={styles.headerContainer}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <h2 className={genericSs.dueDiligenceHeader}>Edit Mapping</h2>
            </Box>
          </div>
          <div className={styles.bodyContainer}>
            <CapTableMappingTable
              selectedDate={mappingDate}
              clientName={ongoingReporting?.clientName}
              setMappingComplete={setMappingComplete}
            />
          </div>
        </FullscreenModal>
      )}
    </Box>
  )
}

export default CapTablePage

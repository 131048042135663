import React from 'react'

import styles from './NewsContainer.module.scss'
import { ReactComponent as RectangleItem } from '@assets/images/rectangle.svg'

import { INewsfeed } from '@common/interfaces/newsfeed'
import Link from '@mui/material/Link'
import { formatDateCalendarNoTime } from '../../helpers/helpers'
import { textToTitleCase } from '@common/helpers/helpers'

interface IProps {
  item: INewsfeed
}

const NewsItem = ({ item }: IProps) => {
  return (
    <div className={styles.item}>
      <div className={styles.titleContainer}>
        <div className={styles.titleRectangle}>
          <RectangleItem />
          <div className={styles.itemDescription}>
            {item?.type || item?.clientNewsFeed?.[0].type || 'Distribution'}
          </div>
        </div>
        <div className={styles.itemDate}>{formatDateCalendarNoTime(item?.updatedAt)}</div>
      </div>

      <div className={styles.itemSubtitles}>
        {item.link && (
          <Link
            className={styles.itemLink}
            color="primary"
            href={item.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {textToTitleCase(item.headline || '')}
          </Link>
        )}
      </div>
    </div>
  )
}

export default NewsItem

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Box } from '@mui/material'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Form } from 'react-final-form'
import cn from 'classnames'

import styles from './ClientLoanLedger.module.scss'
import genericSs from '@styles/generic.module.scss'

import { debounceEventHandler, formatPrice } from '../../../helpers/helpers'
import Card from '../../Common/Card'
import Table from '../../Common/Table'
import TableHead from '../../Common/TableHead'
import TableRow from '../../Common/TableRow'
import TableContainer from '../../Common/TableContainer'
import TableBody from '../../Common/TableBody'
import { ILoadingData } from '../../../redux/types'
import { IParticipationData, ParticipationStatus } from '@common/interfaces/participant'
import useTable from '../../../hooks/useTable'
import { CLIENT_LOAN_LEDGER_FILTERS_CONFIG, PER_PAGE } from '@common/constants/filters'
import {
  buildFiltersDefaults,
  buildFiltersValidateSchema,
  updateDateFilters,
} from '../../../helpers/filters'
import TableFiltersRow from '../../Common/TableFiltersRow'
import FilterContainer from '../../Filters/FilterContainer'
import TableLoader from '../../Common/TableLoader'
import FormattedTableCell from '../../Common/FormattedTableCell'
import ActiveToolbar from '../../ActiveToolbar'
import SaveState from '../../Common/SaveState'
import TableCell from '../../Common/TableCell'
import { ExpandDetailIcon } from '../../Common/Icons'
import { sleep } from '@common/helpers/helpers'
import { usePermissions } from '../../../helpers/permissionContext'
import ExportButton from '../../Common/ExportButton'
import { LoanLedgerWiresTable } from './LoanLedgerWiresTable'
import { IClientLoanLedgerData } from '@common/interfaces/client'
import AddButton from '../AddButton'
import ClientAccountActivityCreateModal from '../ClientAccountActivityCreateModal'
import { CLIENT_PAGE_ACTIVITY_OPTIONS, ClientPageActivityOption } from '../../../constants/routes'
import SelectField from '../../Common/SelectField'

const filtersValidate = buildFiltersValidateSchema(CLIENT_LOAN_LEDGER_FILTERS_CONFIG)
const filtersDefaults = buildFiltersDefaults(CLIENT_LOAN_LEDGER_FILTERS_CONFIG)
const sortDefault = { field: 'record_date', direction: 'DESC' } as const

interface IProps {
  loanLedger: ILoadingData<IClientLoanLedgerData>
  listLoanLedger: (id: string, data: object) => void
  clientParticipations: ILoadingData<IParticipationData>
  listClientParticipations: (id: string, data?: object) => void
  exportParticipationActivity: (id: string, data: object) => void
  refreshCount: number
  setActivityOption: (option: ClientPageActivityOption) => void
}

const ClientLoanLedger = ({
  loanLedger,
  listLoanLedger,
  clientParticipations,
  listClientParticipations,
  exportParticipationActivity,
  refreshCount,
  setActivityOption,
}: IProps) => {
  const { id } = useParams<{ id: string }>()
  const { isExtendedRightsRole } = usePermissions()

  const [isCreateModalShown, setIsCreateModalShown] = useState(false)
  const [isExporting, setIsExporting] = useState(false)

  const [expanded, setExpanded] = useState([])

  const handleExpand = useCallback((label: string) => {
    setExpanded((values) =>
      values.includes(label) ? values.filter((item) => item !== label) : [...values, label],
    )
  }, [])

  const { loanLedgerList, loanLedgerListLength, totalCount, isLoading, isSaving, isSaved } =
    useMemo(
      () => ({
        loanLedgerList: loanLedger?.data?.data,
        loanLedgerListLength: loanLedger?.data?.data?.length,
        totalCount: loanLedger?.data?.totalCount,
        isLoading: loanLedger?.isLoading,
        isSaving: loanLedger?.isSaving,
        isSaved: loanLedger?.isSaved,
      }),
      [loanLedger],
    )

  const { participationsList } = useMemo(
    () => ({
      participationsList: clientParticipations?.data?.data,
      isParticipationLoading: clientParticipations?.isLoading,
      totalCount: clientParticipations?.data?.totalCount,
    }),
    [clientParticipations],
  )

  const effectiveParticipations = useMemo(
    () => participationsList?.filter((item) => item.status === ParticipationStatus.Active),
    [participationsList],
  )

  useEffect(() => {
    listClientParticipations(id)
  }, [listClientParticipations, id])

  const {
    filters,
    handleFiltersChange,
    handleOrderChange,
    orderBy,
    activeItem,
    activeItems,
    setActiveItem,
    setActiveItems,
    handleSelectRow,
    resetActiveItems,
  } = useTable({
    tableId: 'loanLedger',
    filtersDefaults,
    sortDefault,
  })

  const debounceFilterList = useMemo(
    () =>
      debounceEventHandler((data: any) => {
        listLoanLedger(id, {
          ...data,
        })
      }, 500),
    [id, listLoanLedger],
  )

  const handleLoadData = useCallback(() => {
    listLoanLedger(id, {
      page: 0,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters: updateDateFilters(filters, CLIENT_LOAN_LEDGER_FILTERS_CONFIG),
    })
  }, [listLoanLedger, id, orderBy, filters])

  useEffect(() => {
    sleep(1000).then(() => {
      refreshCount && handleLoadData()
    })
  }, [refreshCount, handleLoadData])

  const loadMore = useCallback(() => {
    debounceFilterList({
      loadMore: true,
      page: Math.ceil(loanLedgerListLength / PER_PAGE),
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
    })
  }, [orderBy, filters, debounceFilterList, loanLedgerListLength])

  const totalRow = useMemo(() => {
    const activeRows = loanLedgerList?.filter((_, index) => activeItems.includes(index)) || []
    const rowCount = activeRows.length

    const summedValues = activeRows.reduce(
      (totalRowResult, row) => {
        totalRowResult.fundsIn += row.fundsIn
        totalRowResult.fundsOut += row.fundsOut
        totalRowResult.totalFees += row.totalFees
        totalRowResult.loanBalance += row.loanBalance
        totalRowResult.loanBalancePrev += row.loanBalancePrev
        totalRowResult.dwightExposure += row.dwightExposure

        return totalRowResult
      },
      {
        fundsIn: 0,
        fundsOut: 0,
        totalFees: 0,
        loanBalance: 0,
        loanBalancePrev: 0,
        dwightExposure: 0,
      },
    )

    return {
      ...summedValues,
      loanBalance: rowCount > 0 ? summedValues.loanBalance / rowCount : 0,
      loanBalancePrev: rowCount > 0 ? summedValues.loanBalancePrev / rowCount : 0,
      dwightExposure: rowCount > 0 ? summedValues.dwightExposure / rowCount : 0,
    }
  }, [activeItems, loanLedgerList])

  const handleOpenModal = useCallback(() => {
    setIsCreateModalShown(true)
  }, [])

  const handleCloseModal = useCallback(() => {
    setIsCreateModalShown(false)
  }, [])

  const handleExportParticipationActivity = useCallback(async () => {
    setIsExporting(true)
    await exportParticipationActivity(id, {
      recordDateTo: filters.recordDateTo,
      recordDateFrom: filters.recordDateFrom,
    })
    setIsExporting(false)
  }, [exportParticipationActivity, id, filters])

  const isClientWithParticipants = useMemo(
    () => clientParticipations?.data && clientParticipations.data.data.length > 0,
    [clientParticipations],
  )

  const filtersConfig = useMemo(() => {
    const newFiltersConfig = [...CLIENT_LOAN_LEDGER_FILTERS_CONFIG]

    participationsList?.forEach((participation) => {
      newFiltersConfig.push({
        title: `Interest & Fees`,
        field: `${participation.id}_fees`,
        type: 'amount',
        isHighlighted: true,
        align: 'right',
      })
      newFiltersConfig.push({
        title: `Funds To/(From) Dwight`,
        field: `${participation.id}_funds`,
        type: 'amount',
        isHighlighted: true,
        align: 'right',
      })
      newFiltersConfig.push({
        title: `Exposure`,
        field: `${participation.id}_exposure`,
        type: 'amount',
        isHighlighted: true,
        align: 'right',
      })
      newFiltersConfig.push({
        title: `Exposure %`,
        field: `${participation.id}_portion`,
        type: 'percent',
        isHighlighted: true,
        align: 'right',
      })
    })
    return newFiltersConfig
  }, [participationsList])

  const handleActivityOptionChange = useCallback(
    ({ target: { value } }) => {
      setActivityOption && setActivityOption(value)
    },
    [setActivityOption],
  )

  if (!clientParticipations?.data) {
    return null
  }

  return (
    <Card noHeaderMargin>
      <TableContainer
        className={styles.table}
        onActiveRowsChange={setActiveItems}
        onActiveRowChange={setActiveItem}
        hasFooter
        size="small"
      >
        <Form
          validate={filtersValidate}
          onSubmit={handleFiltersChange}
          initialValues={filters}
          mutators={{
            setFieldData: ([field, value], state, { changeValue }) => {
              changeValue(state, field, () => value)
            },
          }}
          render={({ values, handleSubmit, form: { mutators } }) => (
            <FilterContainer
              filters={CLIENT_LOAN_LEDGER_FILTERS_CONFIG}
              handleSubmit={handleSubmit}
              mutators={mutators}
              values={values}
              appliedFilters={filters}
              title="Loan Ledger"
              actions={
                <Box display="flex" justifyContent="space-between" alignItems="center" gap={1}>
                  <SelectField
                    useFinalForm={false}
                    name="option"
                    value={ClientPageActivityOption.LoanLedger}
                    onChange={handleActivityOptionChange}
                    className={styles.selectField}
                    label="View"
                    variant="outlined"
                    options={CLIENT_PAGE_ACTIVITY_OPTIONS}
                    fullWidth={false}
                  />
                  {isExtendedRightsRole && (
                    <AddButton variant="outlined" onClick={handleOpenModal} />
                  )}
                  <ExportButton
                    isLoading={isExporting}
                    handleExport={handleExportParticipationActivity}
                  />
                </Box>
              }
            />
          )}
        />
        <Table>
          <TableHead>
            {isClientWithParticipants && (
              <TableRow>
                <TableCell />
                <TableCell />
                <TableCell colSpan={CLIENT_LOAN_LEDGER_FILTERS_CONFIG.length - 1}>
                  Client Activity
                </TableCell>
                {participationsList?.map((participation) => (
                  <TableCell
                    key={participation.id}
                    colSpan={4}
                    className={styles.highlightedCurrent}
                  >
                    {participation.participant}
                  </TableCell>
                ))}
              </TableRow>
            )}
            <TableFiltersRow
              filters={filtersConfig}
              orderBy={orderBy}
              handleOrderChange={handleOrderChange}
              isChildrenAtStart
            >
              <TableCell />
            </TableFiltersRow>
          </TableHead>
          <TableBody id="loanLedgerTable" className={styles.scrollableTableBody}>
            {isLoading ? (
              <TableLoader columnsCount={filtersConfig?.length} />
            ) : (
              <InfiniteScroll
                dataLength={loanLedgerListLength || 0}
                next={loadMore}
                hasMore={loanLedgerListLength < totalCount}
                loader={<TableLoader columnsCount={filtersConfig?.length} rowsCount={1} />}
                scrollableTarget="loanLedgerTable"
              >
                {loanLedgerList?.map((row, index) => {
                  const isExpanded = expanded.includes(row.id)
                  const showExpand =
                    row.participationLoanExposure?.reduce(
                      (acc, curr) =>
                        acc + curr.fundsIn - curr.fundsOut - curr.interestSent - curr.feesOut,
                      0,
                    ) !== 0 || row.wires?.length > 0

                  return (
                    <React.Fragment key={row.id}>
                      <TableRow
                        key={row.id}
                        data-index={index}
                        className={cn('activableRow', {
                          activeRow: activeItems.includes(index),
                          currentActiveRow: activeItem === index,
                        })}
                        onClick={(event) => handleSelectRow(event, index)}
                      >
                        <TableCell className={genericSs.tableTextCenter}>
                          {showExpand && (
                            <ExpandDetailIcon
                              onClick={() => handleExpand(row.id)}
                              isExpanded={isExpanded}
                            />
                          )}
                        </TableCell>
                        {CLIENT_LOAN_LEDGER_FILTERS_CONFIG.filter(
                          ({ type }) => type !== 'empty',
                        ).map(({ field, type }) => {
                          const value = row[field]
                          const nullCondition = !value
                          return (
                            <FormattedTableCell
                              key={field}
                              value={value}
                              fieldType={type}
                              nullCondition={nullCondition}
                            />
                          )
                        })}
                        {participationsList?.map((participation) => {
                          const participationExposure = row.participationLoanExposure?.find(
                            (exposure) => exposure.participationId === participation.id,
                          )
                          return (
                            <>
                              <FormattedTableCell
                                key={`${participation?.id}_fees`}
                                value={
                                  participationExposure?.fees -
                                  participationExposure?.feeOverrides +
                                  participationExposure?.interestApplied +
                                  participationExposure?.monthEndInterest
                                }
                                fieldType={'amount'}
                              />

                              <FormattedTableCell
                                key={`${participation?.id}_funds`}
                                value={
                                  participationExposure?.fundsIn -
                                  participationExposure?.fundsOut -
                                  participationExposure?.interestSent -
                                  participationExposure?.feesOut
                                }
                                fieldType={'amount'}
                              />
                              <FormattedTableCell
                                key={`${participationExposure?.id}_exposure`}
                                value={participationExposure?.participantExposureAmount}
                                fieldType={'amount'}
                              />
                              <FormattedTableCell
                                key={`${participationExposure?.id}_portion`}
                                value={participationExposure?.participantExposurePercentage}
                                fieldType={'percent'}
                              />
                            </>
                          )
                        })}
                      </TableRow>
                      {isExpanded && row?.wires?.length > 0 && (
                        <TableRow key={`wires-${row.id}`}>
                          <TableCell className={genericSs.nestedRowColumn} colSpan={7}>
                            <LoanLedgerWiresTable
                              clientId={id}
                              wires={row.wires}
                              effectiveParticipations={effectiveParticipations}
                            />
                          </TableCell>
                        </TableRow>
                      )}
                    </React.Fragment>
                  )
                })}
              </InfiniteScroll>
            )}
          </TableBody>
        </Table>
        <ActiveToolbar
          activeItems={activeItems}
          className={cn(styles.activeToolbar, {
            [styles.activeToolbarParticipants]: participationsList?.length > 0,
          })}
          resetActiveItems={resetActiveItems}
        >
          {CLIENT_LOAN_LEDGER_FILTERS_CONFIG.filter(
            ({ type }) => type !== 'empty' && type === 'amount',
          ).map(({ field, type }) => {
            const value = totalRow?.[field]
            return (
              <div className={genericSs.tableTextRight}>
                <span className={genericSs.pricePrefix}>$</span>
                {formatPrice(value)}
              </div>
            )
          })}
        </ActiveToolbar>
      </TableContainer>
      <Box justifyContent={'flex-end'} display={'flex'} mt={2}>
        <SaveState isSaving={isSaving} isSaved={isSaved} />
      </Box>

      {isCreateModalShown && (
        <ClientAccountActivityCreateModal
          handleCancel={handleCloseModal}
          handleConfirm={handleCloseModal}
        />
      )}
    </Card>
  )
}

export default ClientLoanLedger

import { createRequestTypes } from '../../helpers/helpers'
import { IApi } from '../../api'
import { CommonPromiseAction } from '../types'

export const prefix = 'generalLedger'

export const UPDATE_GENERAL_LEDGER_REQUEST = `${prefix}/UPDATE_GENERAL_LEDGER_REQUEST`
export const UPDATE_GENERAL_LEDGER_SUCCESS = `${prefix}/UPDATE_GENERAL_LEDGER_SUCCESS`
export const UPDATE_GENERAL_LEDGER_FAILURE = `${prefix}/UPDATE_GENERAL_LEDGER_FAILURE`

export const updateGeneralLedger: CommonPromiseAction = (id: string, data: object | FormData) => ({
  type: [
    UPDATE_GENERAL_LEDGER_REQUEST,
    UPDATE_GENERAL_LEDGER_SUCCESS,
    UPDATE_GENERAL_LEDGER_FAILURE,
  ],
  promise: (api: IApi) => api.generalLedger.processGeneralLedger(id, data),
})

export const LIST_TYPES_REQUEST = `${prefix}/LIST_TYPES_REQUEST`
export const LIST_TYPES_SUCCESS = `${prefix}/LIST_TYPES_SUCCESS`
export const LIST_TYPES_FAILURE = `${prefix}/LIST_TYPES_FAILURE`

export const listTypes: CommonPromiseAction = () => ({
  type: [LIST_TYPES_REQUEST, LIST_TYPES_SUCCESS, LIST_TYPES_FAILURE],
  promise: (api: IApi) => api.generalLedger.listTypes(),
})

export const LIST_TYPE_MAPPING_REQUEST = `${prefix}/LIST_TYPE_MAPPING_REQUEST`
export const LIST_TYPE_MAPPING_SUCCESS = `${prefix}/LIST_TYPE_MAPPING_SUCCESS`
export const LIST_TYPE_MAPPING_FAILURE = `${prefix}/LIST_TYPE_MAPPING_FAILURE`

export const listTypeMapping: CommonPromiseAction = (id: string, params: object = {}) => ({
  type: [LIST_TYPE_MAPPING_REQUEST, LIST_TYPE_MAPPING_SUCCESS, LIST_TYPE_MAPPING_FAILURE],
  promise: (api: IApi) => api.generalLedger.listTypeMapping(id, params),
  params,
})

export const UPDATE_TYPE_MAPPING_REQUEST = `${prefix}/UPDATE_TYPE_MAPPING_REQUEST`
export const UPDATE_TYPE_MAPPING_SUCCESS = `${prefix}/UPDATE_TYPE_MAPPING_SUCCESS`
export const UPDATE_TYPE_MAPPING_FAILURE = `${prefix}/UPDATE_TYPE_MAPPING_FAILURE`

export const updateTypeMapping: CommonPromiseAction = (id: string, data: object) => ({
  type: [UPDATE_TYPE_MAPPING_REQUEST, UPDATE_TYPE_MAPPING_SUCCESS, UPDATE_TYPE_MAPPING_FAILURE],
  promise: (api: IApi) => api.generalLedger.updateTypeMapping(id, data),
})

export const HIDE_ALIASES = `${prefix}/HIDE_ALIASES`

export const hideAliases: CommonPromiseAction = () => ({
  type: HIDE_ALIASES,
})

export const HIDE_TYPE_MAPPING = `${prefix}/HIDE_TYPE_MAPPING`

export const hideTypeMapping: CommonPromiseAction = () => ({
  type: HIDE_TYPE_MAPPING,
})

export const LIST_DILUTION_BY_CUSTOMER_REQUEST = `${prefix}/LIST_DILUTION_BY_CUSTOMER_REQUEST`
export const LIST_DILUTION_BY_CUSTOMER_SUCCESS = `${prefix}/LIST_DILUTION_BY_CUSTOMER_SUCCESS`
export const LIST_DILUTION_BY_CUSTOMER_FAILURE = `${prefix}/LIST_DILUTION_BY_CUSTOMER_FAILURE`

export const listDilutionByCustomer: CommonPromiseAction = (id: string, params: object = {}) => ({
  type: [
    LIST_DILUTION_BY_CUSTOMER_REQUEST,
    LIST_DILUTION_BY_CUSTOMER_SUCCESS,
    LIST_DILUTION_BY_CUSTOMER_FAILURE,
  ],
  promise: (api: IApi) => api.generalLedger.listDilutionByCustomer(id, params),
  params,
})

export const HIDE_DILUTION_BY_CUSTOMER = `${prefix}/HIDE_DILUTION_BY_CUSTOMER`

export const hideDilutionByCustomer: CommonPromiseAction = () => ({
  type: HIDE_DILUTION_BY_CUSTOMER,
})

export const LIST_DILUTION_OVER_TIME_REQUEST = `${prefix}/LIST_DILUTION_OVER_TIME_REQUEST`
export const LIST_DILUTION_OVER_TIME_SUCCESS = `${prefix}/LIST_DILUTION_OVER_TIME_SUCCESS`
export const LIST_DILUTION_OVER_TIME_FAILURE = `${prefix}/LIST_DILUTION_OVER_TIME_FAILURE`

export const listDilutionOverTime: CommonPromiseAction = (id: string, params: object = {}) => ({
  type: [
    LIST_DILUTION_OVER_TIME_REQUEST,
    LIST_DILUTION_OVER_TIME_SUCCESS,
    LIST_DILUTION_OVER_TIME_FAILURE,
  ],
  promise: (api: IApi) => api.generalLedger.listDilutionOverTime(id, params),
  params,
})

export const HIDE_DILUTION_OVER_TIME = `${prefix}/HIDE_DILUTION_OVER_TIME`

export const hideDilutionOverTime: CommonPromiseAction = () => ({
  type: HIDE_DILUTION_OVER_TIME,
})

const LIST_DILUTION_DROPDOWNS = createRequestTypes(prefix, `/LIST_DILUTION_DROPDOWNS`)

export const [
  LIST_DILUTION_DROPDOWNS_REQUEST,
  LIST_DILUTION_DROPDOWNS_SUCCESS,
  LIST_DILUTION_DROPDOWNS_FAILURE,
] = LIST_DILUTION_DROPDOWNS
export const listDilutionDropdowns: CommonPromiseAction = (id: string) => ({
  type: LIST_DILUTION_DROPDOWNS,
  promise: (api: IApi) => api.generalLedger.listDilutionDropdowns(id),
})

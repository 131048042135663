import React, { useEffect, useCallback, useState, useMemo } from 'react'
import { useLocation, useHistory, generatePath } from 'react-router-dom'
import queryString from 'query-string'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'

import cn from 'classnames'
import { useSetPageTitle } from '../../hooks/useSetPageTitle'
import { ReactComponent as HomeIcon } from '@assets/images/home-icon.svg'
import { ActivityStatus } from '@common/interfaces/activity'
import WarningModal from '../../components/WarningModal/WarningModal'

import styles from './CollectionsChecksPage.module.scss'
import genericSs from '@styles/generic.module.scss'
import { ROUTES } from '../../constants/routes'
import ChecksUploads from '../../components/ChecksUploads'
import Card from '../../components/Common/Card'
import {
  ICheck,
  CheckDataStatus,
  ICheckData,
  IChecksSummaryInfo,
} from '@common/interfaces/collection'
import Table from '../../components/Common/Table'
import TableHead from '../../components/Common/TableHead'
import TableRow from '../../components/Common/TableRow'
import TableCell from '../../components/Common/TableCell'
import TableBody from '../../components/Common/TableBody'
import TableContainer from '../../components/Common/TableContainer'
import Button from '../../components/Common/Button'
import { ClientInfoStatus, IClientInfo } from '@common/interfaces/client'
import { formatPrice, unFormatPrice, debounceEventHandler } from '../../helpers/helpers'
import CurrencyField from '../../components/Common/CurrencyField'
import { EntitySubType, IEntityInfo } from '@common/interfaces/entityInfo'

import moment from 'moment'
import { ActivityType } from '@common/interfaces/activity'
import {
  historyChangeEventHandler,
  visibilityChangeEventHandler,
  formatDate,
} from '../../helpers/helpers'
import Breadcrumbs from '../../components/Common/Breadcrumbs'
import { FieldArray } from 'react-final-form-arrays'
import arrayMutators from 'final-form-arrays'
import setFieldData from 'final-form-set-field-data'
import ActiveToolbar from '../../components/ActiveToolbar'
import CreatableSelectField from '../../components/Common/CreatableSelectField'
import { formatter } from '../../helpers/helpers'
import { Form } from 'react-final-form'
import MappingProgress from '../../components/MappingProgress/MappingProgress'
import TableFiltersRow from '../../components/Common/TableFiltersRow'
import { CHECKS_FILTERS_CONFIG, PER_PAGE } from '@common/constants/filters'
import FilterContainer from '../../components/Filters/FilterContainer'
import { buildFiltersValidateSchema, buildFiltersDefaults } from '../../helpers/filters'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import { ILoadingData } from '../../redux/types'
import { MenuIcon } from '../../components/Common/Icons'
import TableLoader from '../../components/Common/TableLoader'
import InfiniteScroll from 'react-infinite-scroll-component'
import useTable from '../../hooks/useTable'
import CheckImageCarousel from './Components/CheckImageCarousel'
import CheckRow from './Components/CheckRow'
import SaveState from '../../components/Common/SaveState'

interface IProps {
  isLoading: boolean
  checkData: ILoadingData<ICheckData>
  checksSummaryInfo: IChecksSummaryInfo
  clients: ILoadingData<{ data: IClientInfo[] }>
  collectionStatus?: string
  nonClientCashChecks: ICheck[]
  listChecks: (params: object) => void
  saveChecks: (data: object) => void
  updateCheck: (id: string, data: object) => void
  updateChecks: (data: object) => void
  restoreCheck: (id: string) => void
  deleteCheck: (id: string, data: object) => void
  listClients: (params: object) => void
  listEntityInfo: (data: object) => Promise<{ data: IEntityInfo[] }>
  addEntityInfo: (data: object) => Promise<any>
  trackActivity: (data: object, beacon?: boolean) => void
  loadCheckImage: (id: string) => Promise<string>
  getChecksSummaryInfo: (params: object) => void
}

const mutators = {
  ...arrayMutators,
  setFieldData,
}

const filtersDefaults = buildFiltersDefaults(CHECKS_FILTERS_CONFIG)
const filtersValidate = buildFiltersValidateSchema(CHECKS_FILTERS_CONFIG)

const CollectionsChecksPage = ({
  checkData,
  checksSummaryInfo,
  clients,
  collectionStatus,
  listChecks,
  saveChecks,
  updateCheck,
  updateChecks,
  restoreCheck,
  deleteCheck,
  listClients,
  listEntityInfo,
  addEntityInfo,
  trackActivity,
  loadCheckImage,
  getChecksSummaryInfo,
}: IProps) => {
  const [selectedCheck, selectCheck] = useState(null)
  const [isDeleteModalShown, setIsDeleteModalShown] = useState(false)
  const [isPermanent, setIsPermanent] = useState(false)
  const [deposits, setDeposits] = useState({})

  const [anchorEl, setAnchorEl] = useState(null)
  const [actionsMenuOpen, setActionsMenuOpen] = useState(false)
  const [actionsMenuCashOpen, setActionsMenuCashOpen] = useState(false)

  const [refreshCounter, setRefreshCounter] = useState(1)

  const history = useHistory()
  const { search, pathname }: { search: string; pathname: string } = useLocation()
  const qs = queryString.parse(search)

  const {
    filters,
    orderBy,
    handleFiltersChange,
    handleOrderChange,
    activeItem,
    activeItems,
    setActiveItem,
    setActiveItems,
    handleSelectRow,
  } = useTable({
    tableId: 'check-mapping',
    filtersDefaults,
    sortDefault: { field: '', direction: '' },
  })

  const { checks, totalCount, isLoading, isSaving, isSaved } = useMemo(
    () => ({
      checks: checkData?.data?.data,
      totalCount: checkData?.data?.totalCount,
      isLoading: checkData?.isLoading,
      isSaving: checkData?.isSaving,
      isSaved: checkData?.isSaved,
    }),
    [checkData],
  )

  const { checksReconciliation, mappingProgress, nonClientCashChecks } = useMemo(
    () => ({
      checksReconciliation: checksSummaryInfo?.checksReconciliation || [],
      mappingProgress: checksSummaryInfo?.mappingProgress || 0,
      nonClientCashChecks: checksSummaryInfo?.nonClientCashChecks || [],
    }),
    [checksSummaryInfo],
  )

  useSetPageTitle(checks?.length ? `Checks ${formatDate(checks[0].recordDate)}` : 'Checks')

  const { clientsData } = useMemo(
    () => ({
      clientsData: clients?.data?.data,
    }),
    [clients],
  )

  const breadcrumbs = useMemo(() => {
    return [
      {
        link: ROUTES.HOMEPAGE,
        Icon: HomeIcon,
      },
      {
        link: ROUTES.ACTIVITY_QUEUE,
        title: 'Operations',
      },
      {
        link: generatePath(ROUTES.COLLECTIONS_CHECKS + search),
        title: checks?.length ? `Checks ${formatDate(checks[0].recordDate)}` : 'Checks',
      },
    ]
  }, [checks, search])

  useEffect(() => {
    if (!qs.activityId) {
      history.push(ROUTES.HOMEPAGE)
    }
  }, [qs, history])

  useEffect(() => {
    if (qs.activityId) {
      getChecksSummaryInfo({ activityId: qs.activityId })
    }
  }, [qs.activityId, getChecksSummaryInfo])

  useEffect(() => {
    listClients({ statuses: [ClientInfoStatus.Past, ClientInfoStatus.Current] })
  }, [listClients])

  const debounceListSummary = useMemo(
    () =>
      debounceEventHandler((data: any) => {
        listChecks({
          activityId: qs.activityId,
          ...data,
        })
      }, 500),
    [qs.activityId, listChecks],
  )

  useEffect(() => {
    refreshCounter &&
      debounceListSummary({
        filters,
        orderBy: orderBy.field,
        orderDirection: orderBy.direction,
        page: 0,
        perPage: PER_PAGE,
      })
  }, [filters, orderBy, debounceListSummary, refreshCounter])

  const handleChangeDeposit = useCallback(
    (lockbox: number, deposit: any) => {
      const values = { ...deposits }
      values[lockbox] = deposit

      setDeposits(values)
    },
    [deposits],
  )

  const initialValues = useMemo(
    () => ({
      checks: checks?.map(
        ({ id, accountNumber, checkAccount, clientInfo, status, originalEntity }) => ({
          id,
          accountNumber,
          checkAccount: checkAccount
            ? {
                id: checkAccount.id,
                value: checkAccount.linkedName || '',
                label: checkAccount.linkedName || '',
              }
            : null,
          clientName: clientInfo?.clientName || null,
          status,
          originalEntity,
          isBillPaymentProvider:
            checkAccount?.entityInfo?.type === EntitySubType.BillPaymentProvider,
        }),
      ),
      activityId: qs?.activityId,
    }),
    [checks, qs],
  )

  const activeChecksItems = useMemo(
    () => checks?.filter((_, index) => activeItems.includes(index)),
    [activeItems, checks],
  )

  const clientsOptions = useMemo(
    () =>
      clientsData?.map(({ clientName }) => ({
        value: clientName,
        label: clientName,
      })),
    [clientsData],
  )

  const loadDebtors = useCallback(
    async (inputValue: string) => {
      const res = await listEntityInfo({
        name: inputValue,
      })
      return res.data.map(({ id, name: value }) => ({
        id,
        value,
        label: value,
      }))
    },
    [listEntityInfo],
  )

  const totalRow = useMemo(
    () =>
      checks
        ?.filter((_, index) => activeItems.includes(index))
        .reduce(
          (result, row) => {
            result.paymentAmount += row.paymentAmount || 0

            return result
          },
          {
            paymentAmount: 0,
          },
        ),
    [checks, activeItems],
  )
  const handleRestoreActive = useCallback(() => {
    updateChecks({
      ids: activeChecksItems.map(({ id }) => id),
      status: CheckDataStatus.New,
    })
    setActiveItems([])
    setRefreshCounter((prev) => prev + 1)
  }, [activeChecksItems, updateChecks, setActiveItems])

  const handleClickMenu = useCallback((check: ICheck, event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    setActionsMenuOpen(true)
    selectCheck(check)
  }, [])
  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null)
    setActionsMenuOpen(false)
    selectCheck(null)
  }, [])

  const handleClickCashMenu = useCallback((check: ICheck, event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    setActionsMenuCashOpen(true)
    selectCheck(check)
  }, [])
  const handleCloseCashMenu = useCallback(() => {
    setAnchorEl(null)
    setActionsMenuCashOpen(false)
    selectCheck(null)
  }, [])

  const handleArchiveActive = useCallback(() => {
    setIsPermanent(false)
    setIsDeleteModalShown(true)
  }, [])

  const handleDeleteActive = useCallback(() => {
    setIsPermanent(true)
    setIsDeleteModalShown(true)
  }, [])
  const isActiveChecksItemsContainDeleted = useMemo(
    () => !!activeChecksItems?.find(({ status }) => status === CheckDataStatus.Deleted),
    [activeChecksItems],
  )
  const isActiveChecksItemsContainNew = useMemo(
    () => !!activeChecksItems?.find(({ status }) => status === CheckDataStatus.New),
    [activeChecksItems],
  )

  const handleDeleteConfirm = useCallback(() => {
    deleteCheck(selectedCheck, { isPermanent })
    handleCloseMenu()
    setIsDeleteModalShown(false)
    selectCheck(null)
    setRefreshCounter((prev) => prev + 1)
  }, [selectedCheck, deleteCheck, isPermanent, handleCloseMenu])

  const handleDeleteCancel = useCallback(() => {
    handleCloseMenu()
    setIsDeleteModalShown(false)
    selectCheck(null)
  }, [handleCloseMenu])

  const handleDeleteActiveConfirm = useCallback(() => {
    updateChecks({
      ids: activeChecksItems.map(({ id }) => id),
      status: CheckDataStatus.Deleted,
      isPermanent,
    })
    setIsDeleteModalShown(false)
    setActiveItems([])
    setRefreshCounter((prev) => prev + 1)
  }, [activeChecksItems, updateChecks, isPermanent, setActiveItems])

  const handleDeleteActiveCancel = useCallback(() => {
    setIsDeleteModalShown(false)
    setActiveItems([])
  }, [setActiveItems])
  const handleRestore = useCallback(
    (id: string) => {
      restoreCheck(id)
      handleCloseCashMenu()
      setRefreshCounter((prev) => prev + 1)
    },
    [restoreCheck, handleCloseCashMenu],
  )

  const handleUpdateCheck = useCallback(
    (data: any) => {
      const activeChecksIds = activeChecksItems.map(({ id }) => id)
      const dataToUpdate =
        data?.debtor !== undefined
          ? {
              debtor: data?.debtor,
            }
          : {
              originalEntity: data?.originalEntity,
            }
      if (activeChecksIds.length > 1 && activeChecksIds.includes(data.id)) {
        updateChecks({
          ids: activeChecksIds,
          clientName: data?.clientName,
          ...dataToUpdate,
        })
      } else {
        updateCheck(data.id, {
          clientName: data?.clientName,
          ...dataToUpdate,
        })
      }
      setRefreshCounter((prev) => prev + 1)
    },
    [updateCheck, updateChecks, activeChecksItems],
  )

  const handleArchive = useCallback((id: string) => {
    setIsPermanent(false)
    setIsDeleteModalShown(true)
    selectCheck(id)
  }, [])
  const [startTime, setStartTime] = useState(moment().toISOString())

  const logActivity = useCallback(
    (beacon: boolean = false) => {
      if (qs?.activityId) {
        const endTime = moment().toISOString()
        const data = {
          activityId: qs?.activityId,
          startedAt: startTime,
          finishedAt: endTime,
          workflow: ActivityType.Check,
          step: 'Mapping',
        }
        trackActivity(data, beacon)
        setStartTime(endTime)
      }
    },
    [qs, startTime, trackActivity],
  )
  useEffect(() => {
    const unlisten = historyChangeEventHandler(logActivity, history, pathname)
    return unlisten
  }, [history, pathname, logActivity])

  useEffect(() => {
    const unlisten = visibilityChangeEventHandler(logActivity)
    return unlisten
  }, [logActivity])

  const handleSaveChecks = useCallback(
    (data: object) => {
      logActivity()
      saveChecks({ ...data, activityId: qs.activityId })
    },
    [saveChecks, logActivity, qs.activityId],
  )
  const handleDelete = useCallback((id: string) => {
    setIsPermanent(true)
    setIsDeleteModalShown(true)
    selectCheck(id)
  }, [])

  const handleAddDebtor = useCallback(
    async (debtor: string, currentClient?: string) => {
      return await addEntityInfo({ name: debtor, type: 'debtor', clientName: currentClient })
    },
    [addEntityInfo],
  )

  const loadMore = useCallback(() => {
    listChecks({
      loadMore: true,
      activityId: qs.activityId,
      filters,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      page: Math.ceil(checks?.length / PER_PAGE),
    })
  }, [listChecks, checks, qs.activityId, filters, orderBy])

  const checksToLoad = useMemo(() => {
    const activeIndex = activeItems[0]
    const checksToLoad = []
    if (activeIndex !== undefined) {
      for (let i = activeIndex - 2; i <= activeIndex + 2; i++) {
        if (i >= 0 && i < checks?.length) {
          checksToLoad.push(i)
        }
      }
    }
    return checksToLoad
  }, [activeItems, checks])

  const areChecksValid = useMemo(
    () =>
      checksReconciliation
        .map(
          ({ lockbox, total }) =>
            (Number(unFormatPrice(deposits[lockbox])) || 0).toFixed(2) === Number(total).toFixed(2),
        )
        .every((valid) => valid) && Math.round(mappingProgress * 100) / 100 === 100,
    [checksReconciliation, deposits, mappingProgress],
  )

  return (
    <Box py={1} pr={2}>
      <Grid container spacing={3}>
        <Grid item xs={12} className={styles.headerContainer}>
          <Box mb={2}>
            <div className={styles.breadcrumbsContainer}>
              {<Breadcrumbs breadcrumbs={breadcrumbs} />}
            </div>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            pt={2}
            paddingTop={'15'}
            alignItems="center"
            mb={3}
          >
            <ChecksUploads key={+isLoading} setRefreshCounter={setRefreshCounter} />
            <div className={styles.submitButtonContainer}>
              <Button
                type="button"
                disabled={!areChecksValid}
                color="primary"
                variant="contained"
                size="small"
                onClick={() => handleSaveChecks(checks)}
              >
                {collectionStatus === ActivityStatus.Review ? 'Submit' : 'Submit for Review'}
              </Button>
            </div>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box display="flex" my={1} alignItems="center" className={styles.sectionChecks}>
            {checks?.length > 0 && (
              <CheckImageCarousel
                checksToLoad={checksToLoad}
                activeItems={activeItems}
                setActiveItems={setActiveItems}
                checks={checks}
                handleLoadCheckImage={loadCheckImage}
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Card noHeaderMargin>
            <Form
              onSubmit={handleFiltersChange}
              initialValues={initialValues}
              validate={filtersValidate}
              mutators={mutators}
              render={({ form, values }: { form: any; values: any }) => (
                <TableContainer
                  className={styles.checkDebtorsTable}
                  isActivable
                  onActiveRowChange={setActiveItem}
                  onActiveRowsChange={setActiveItems}
                >
                  <Form
                    mutators={{
                      setFieldData: ([field, value], state, { changeValue }) => {
                        changeValue(state, field, () => value)
                      },
                    }}
                    validate={filtersValidate}
                    onSubmit={handleFiltersChange}
                    initialValues={filters}
                    render={({
                      values: filterValues,
                      handleSubmit: handleFilterSubmit,
                      form: { mutators: filterMutators },
                    }) => (
                      <div className={styles.filtersHeader}>
                        <FilterContainer
                          filters={CHECKS_FILTERS_CONFIG}
                          title={'Check mapping'}
                          handleSubmit={handleFilterSubmit}
                          mutators={filterMutators}
                          values={values}
                          appliedFilters={filterValues}
                          actions={
                            <Box display="flex" position="relative" alignItems="center">
                              {activeItems.length > 1 && (
                                <Box display="flex" alignItems="center" gap={3} mr={1}>
                                  {isActiveChecksItemsContainDeleted && (
                                    <Button
                                      color="primary"
                                      variant="outlined"
                                      onClick={handleRestoreActive}
                                    >
                                      Recover
                                    </Button>
                                  )}

                                  {isActiveChecksItemsContainNew && (
                                    <Button
                                      color="primary"
                                      variant="outlined"
                                      onClick={handleArchiveActive}
                                    >
                                      Non Client Related Cash
                                    </Button>
                                  )}

                                  <Button
                                    color="primary"
                                    variant="outlined"
                                    onClick={handleDeleteActive}
                                  >
                                    Delete
                                  </Button>
                                </Box>
                              )}
                              <MappingProgress value={mappingProgress || 0} />
                            </Box>
                          }
                        />
                      </div>
                    )}
                  />
                  <Table>
                    <TableHead>
                      <TableFiltersRow
                        filters={CHECKS_FILTERS_CONFIG}
                        orderBy={orderBy}
                        handleOrderChange={handleOrderChange}
                        className={styles.filterHeaders}
                      />
                    </TableHead>
                    <TableBody id={`check-mapping-table`}>
                      {isLoading && !checks?.length ? (
                        <TableLoader columnsCount={6} />
                      ) : checks?.length > 0 ? (
                        <>
                          <InfiniteScroll
                            dataLength={checks?.length || 0}
                            next={loadMore}
                            hasMore={checks?.length < (totalCount || 0)}
                            loader={<TableLoader columnsCount={8} rowsCount={1} />}
                            scrollableTarget={`check-mapping-table`}
                          >
                            <FieldArray name="checks">
                              {({ fields }) => (
                                <>
                                  {fields.map((name, index) => {
                                    return (
                                      <CheckRow
                                        key={name}
                                        check={checks?.[index]}
                                        index={index}
                                        activeItems={activeItems}
                                        activeItem={activeItem}
                                        handleSelectRow={handleSelectRow}
                                        handleUpdateCheck={handleUpdateCheck}
                                        values={values}
                                        form={form}
                                        name={name}
                                        clientsOptions={clientsOptions}
                                        loadDebtors={loadDebtors}
                                        handleAddDebtor={handleAddDebtor}
                                        handleClickMenu={handleClickMenu}
                                      />
                                    )
                                  })}
                                </>
                              )}
                            </FieldArray>
                          </InfiniteScroll>
                        </>
                      ) : null}
                    </TableBody>
                  </Table>

                  <ActiveToolbar activeItems={activeItems} className={styles.activeToolbar}>
                    <div className={genericSs.tableTextRight}>
                      {formatter.format(totalRow?.paymentAmount)}
                    </div>
                  </ActiveToolbar>
                  <Menu
                    open={actionsMenuOpen}
                    anchorEl={anchorEl}
                    onClose={handleCloseMenu}
                    className={styles.actionsMenu}
                  >
                    <MenuItem
                      onClick={() => {
                        selectedCheck && handleArchive(selectedCheck.id)
                      }}
                    >
                      Non client related cash
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        selectedCheck && handleDelete(selectedCheck.id)
                      }}
                    >
                      Delete
                    </MenuItem>
                  </Menu>
                  <Grid container justifyContent={'flex-end'}>
                    <SaveState isSaving={isSaving} isSaved={isSaved} />
                  </Grid>
                </TableContainer>
              )}
            />
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card title="Non-client related cash">
            <Form
              onSubmit={(values) => {}}
              initialValues={initialValues}
              mutators={{
                ...arrayMutators,
              }}
              render={({ form }: { form: any }) => (
                <TableContainer className={styles.nonClientRelatedCash}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className={genericSs.tableTextLeft}>Date</TableCell>
                        <TableCell className={genericSs.tableTextLeft}>Check #</TableCell>
                        <TableCell className={genericSs.tableTextLeft}>Client</TableCell>
                        <TableCell className={genericSs.tableTextLeft}>Customers</TableCell>
                        <TableCell className={genericSs.tableTextLeft}>Account number</TableCell>
                        <TableCell className={genericSs.tableTextLeft}>Amount</TableCell>
                        <TableCell className={genericSs.tableTextLeft}>Action</TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {nonClientCashChecks?.length > 0 &&
                        nonClientCashChecks.map((check, index) => {
                          return (
                            <TableRow className={styles.validRow} key={`check-${check.id}`}>
                              <TableCell className={genericSs.tableTextLeft}>
                                {check.recordDate}
                              </TableCell>
                              <TableCell className={genericSs.tableTextLeft}>
                                {check.checkNumber}
                              </TableCell>
                              <TableCell className={genericSs.tableTextLeft}>
                                {check.clientInfo && check.clientInfo.clientName}
                              </TableCell>
                              <TableCell className={genericSs.tableTextLeft}>
                                <CreatableSelectField
                                  label=""
                                  name={'checkAccount'}
                                  value={
                                    check.checkAccount
                                      ? {
                                          label: check.checkAccount.linkedName,
                                          value: check.checkAccount.linkedName,
                                        }
                                      : null
                                  }
                                  tabIndex={2 * index + 1}
                                  className={cn({
                                    focusableInput: true,
                                    [styles.validInput]:
                                      !!check.accountNumber && !!check.checkAccount?.linkedName,
                                    invalidInput:
                                      !check.accountNumber || !check.checkAccount?.linkedName,
                                  })}
                                  placeholder="Select customer"
                                  onAddValue={(debtorName) => handleAddDebtor(debtorName)}
                                  options={[]}
                                  getOptionValue={(option) => option.value}
                                  isAsync
                                  loadOptions={loadDebtors}
                                  onChangeCustom={async (event, newValue: any) => {
                                    if (!newValue) {
                                      handleUpdateCheck({
                                        id: check.id,
                                        debtor: '',
                                      })
                                    } else if (newValue.id) {
                                      handleUpdateCheck({
                                        id: check.id,
                                        debtor: newValue?.value,
                                      })
                                    } else if (newValue.value) {
                                      const result = await handleAddDebtor(newValue.value)
                                      if (!!result?.error) {
                                        return
                                      }
                                      handleUpdateCheck({
                                        id: check.id,
                                        debtor: newValue?.value,
                                      })
                                    }
                                    form.reset()
                                  }}
                                />
                              </TableCell>
                              <TableCell className={genericSs.tableTextLeft}>
                                {check.accountNumber}
                              </TableCell>

                              <TableCell className={genericSs.tableTextLeft}>
                                <span className={genericSs.pricePrefix}>$</span>
                                {formatPrice(check.paymentAmount)}
                              </TableCell>
                              <TableCell className={genericSs.tableTextLeft}>
                                <MenuIcon
                                  onClick={(event) => handleClickCashMenu(check, event)}
                                  size="small"
                                />
                              </TableCell>
                            </TableRow>
                          )
                        })}
                    </TableBody>
                  </Table>
                  <Menu
                    open={actionsMenuCashOpen}
                    anchorEl={anchorEl}
                    onClose={handleCloseCashMenu}
                    className={styles.actionsMenu}
                  >
                    <MenuItem
                      disabled={!selectedCheck?.clientInfo?.clientName}
                      onClick={() => {
                        selectedCheck && handleRestore(selectedCheck.id)
                      }}
                    >
                      Restore
                    </MenuItem>
                  </Menu>
                </TableContainer>
              )}
            />
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card title="Lockbox Reconciliation">
            <TableContainer className={styles.checkReconciliationTable}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className={genericSs.tableTextLeft}>Account</TableCell>
                    <TableCell className={genericSs.tableTextRight}>Total Deposit</TableCell>
                    <TableCell className={genericSs.tableTextRight}>Discrepancy</TableCell>
                    <TableCell className={genericSs.tableTextRight}>Adjustment</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {checksReconciliation.map(({ lockbox, adjustments, total }) => {
                    const deposit = deposits[lockbox] !== undefined ? deposits[lockbox] : 0
                    const discrepancy =
                      Math.round((total - (Number(unFormatPrice(deposit)) || 0)) * 100) / 100

                    return (
                      <TableRow
                        key={lockbox}
                        className={cn({
                          [styles.validRow]: discrepancy === 0,
                          [styles.inValidRow]: discrepancy !== 0,
                        })}
                      >
                        <TableCell className={genericSs.tableTextLeft}>Lockbox {lockbox}</TableCell>
                        <TableCell className={genericSs.tableTextRight}>
                          <CurrencyField
                            useFinalForm={false}
                            name="deposit"
                            value={deposit}
                            onChange={(event) => handleChangeDeposit(lockbox, event.target.value)}
                          />
                        </TableCell>
                        <TableCell className={genericSs.tableTextRight}>
                          <span className={genericSs.pricePrefix}>$</span>
                          {formatPrice(discrepancy)}
                        </TableCell>
                        <TableCell className={genericSs.tableTextRight}>
                          <span className={genericSs.pricePrefix}>$</span>
                          {formatPrice(adjustments)}
                        </TableCell>
                        <TableCell />
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </Grid>
      </Grid>

      {isDeleteModalShown && selectedCheck && (
        <WarningModal
          onCancel={handleDeleteCancel}
          title={isPermanent ? 'Delete Check?' : 'Non Client Related Cash?'}
          onConfirm={() => {
            handleDeleteConfirm()
          }}
          warningMessage={`          Check will be 
                                ${
                                  isPermanent
                                    ? 'deleted permanently'
                                    : 'marked as non client related cash'
                                }. Are you sure?`}
        />
      )}

      {isDeleteModalShown && !selectedCheck && activeItems.length > 1 && (
        <WarningModal
          onCancel={handleDeleteActiveCancel}
          title={isPermanent ? 'Delete Checks?' : 'Non Client Related Cash?'}
          onConfirm={() => {
            handleDeleteActiveConfirm()
          }}
          warningMessage={`          Checks will be 
                      ${
                        isPermanent ? 'deleted permanently' : 'marked as non client related cash'
                      }. Are you sure?`}
        />
      )}
    </Box>
  )
}

export default CollectionsChecksPage
